import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./Routes/AppRoutes";
import { AppProvider } from "./Context/AppContext";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "./Components/Atoms/CustomToastContainer/CustomToastContainer.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AppProvider>
          <AppRoutes />
          <ToastContainer />
        </AppProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
