import { useState } from "react";
import { Text } from "../../Atoms/Typography/Text";
import Container from "../../Atoms/Container/Container";
import CreateOrderForm from "../CreateOrderForm/CreateOrderForm";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import Review from "../Review/Review";
import "./CreateOrder.scss";
import { useNavigate } from "react-router-dom";
const CreateOrder = () => {
  const [count, setCount] = useState(1);
  const [Data, setData] = useState({});
  const [reviewData, setReviewData] = useState({});
  const navigate = useNavigate();
  const user_payment_method = localStorage.getItem("payment_method");
  const handleNextStep = (data) => {
    setReviewData(data);
    setCount(count + 1);
  };
  const handleCancel = () => {
    // setData(reviewData);
    // setCount(1);
    navigate(ROUTES?.ORDER);
  };
  return (
    <Container className={CONSTANTS?.CREATE_ORDER_STEPPER}>
      <Container className={STYLES?.CREATE_ORDER_FORM}>
        <Text variant="h1" className={STYLES?.CREATE_NEW_ORDER_FORM_TITLE}>
          {CONSTANTS?.CREATE_NEW_ORDER}
        </Text>

        <Container className={STYLES?.ORDER_INFORMATION}>
          <Container
            onClick={() => {
              count === 2
                ? setCount(count - 1)
                : count === 1
                ? setCount(1)
                : setCount(3);
            }}
            className={`${STYLES?.ORDER_VIEW_KIND} ${
              count === 1
                ? STYLES?.ACTIVE_STEP
                : STYLES?.ORDER_DETAILS_FROM_LINK
            }`}
          >
            <Text variant="p">
              <span>1.</span>
            </Text>

            <Text variant="p">{CONSTANTS?.ORDER_DETAILS}</Text>
          </Container>
          <Container
            className={`${STYLES?.ORDER_VIEW_KIND} ${
              count === 2
                ? STYLES?.ACTIVE_STEP
                : STYLES?.ORDER_DETAILS_FROM_LINK
            }`}
          >
            <Text variant="p">
              <span>2.</span>
            </Text>

            <Text variant="p">{CONSTANTS?.REVIEW}</Text>
          </Container>
          <Container
            className={`${STYLES?.ORDER_VIEW_KIND} ${
              count === 3
                ? STYLES?.ACTIVE_STEP
                : STYLES?.ORDER_DETAILS_FROM_LINK
            }`}
          >
            {user_payment_method === "stripe" && (
              <>
                <Text variant="p" className={STYLES?.ORDER_DETAILS_FROM_LINK}>
                  <span>3.</span>
                </Text>
                <Text variant="p" className={STYLES?.ORDER_DETAILS_FROM_LINK}>
                  {CONSTANTS?.PAYMENT_DETAILS}
                </Text>
              </>
            )}
          </Container>
        </Container>
        <Container>
          {count === 1 && (
            <CreateOrderForm
              handleNextStep={handleNextStep}
              setData={setData}
              initialData={reviewData}
            />
          )}
          {count === 2 && (
            <Review
              Data={Data}
              handleNextStep={handleNextStep}
              handleCancel={handleCancel}
            />
          )}
          {count === 3 && (
            <PaymentMethod Data={Data} handleCancel={handleCancel} />
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default CreateOrder;
