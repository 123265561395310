import React, { createContext, useState, useEffect } from "react";

const AppContext = createContext(null);

const AppProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState("user");
  const [selectedOrder, setSelectedOrder] = useState({});
  const [userProfile, setUserProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const user = localStorage.getItem("userType");

    setIsAuthenticated(token && user ? true : false);
    setUserType(user);
  }, []);

  const contextValue = {
    isAuthenticated,
    setIsAuthenticated,
    userType,
    setUserType,
    selectedOrder,
    setSelectedOrder,
    userProfile,
    setUserProfile,
    isLoading,
    setIsLoading,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {props?.children}
    </AppContext.Provider>
  );
};

export default AppContext;
export { AppProvider };
