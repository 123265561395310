import React from "react";
import Container from "../../Atoms/Container/Container";
import "./TextArea.scss";
import { STYLES } from "../../../Helpers/Constants";
import { Text } from "../../Atoms/Typography/Text";

const TextArea = ({ onTextChange, placeholder, textAreaTitle, ...rest }) => {
  return (
    <Container>
      <Text className={STYLES?.TEXT_AREA_TITLE}>{textAreaTitle}</Text>
      <textarea
        onChange={onTextChange}
        className={STYLES?.TEXT_AREA}
        placeholder={placeholder}
        {...rest}
      />
    </Container>
  );
};

export default TextArea;
