/** @format */

import React from "react";

export const Text = ({ variant, children, className }) => {
  let style;

  switch (variant) {
    case "h1":
      style = {
        fontFamily: "Inter",
      };
      break;
    case "h5":
      style = {
        fontFamily: "Inter",
        color: "",
      };
      break;
    case "p":
      style = {
        fontFamily: "Inter",
      };
      break;
    case "normal":
      style = {
        fontFamily: "Inter",
        color: "#111FED",
        fontSize: 16,
      };
      break;
    default:
      style = {};
  }
  return (
    <p style={style} className={className}>
      {children}
    </p>
  );
};
