import React, { useEffect, useRef } from "react";
import "./Sort.scss";
import SortIcon from "../../../Assets/Icon.png";
import Image from "../Image/Image";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";

const Sort = ({ setVisibility, visibility, Sort, setSort }) => {
  const sortRef = useRef(null);
  const options = [
    { id: 1, label: "Ascending", value: "ASC", checked: false },
    { id: 2, label: "Descending", value: "DESC", checked: false },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setVisibility(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setVisibility]);

  const handleOptionClick = (value) => {
    setSort(Sort === value ? "" : value);
  };

  return (
    <div
      className="select"
      onClick={(e) => {
        setVisibility(!visibility);
      }}
      ref={sortRef}
    >
      <div className={STYLES?.SORT_BOX_INNER_CONTAINER}>
        <i className="sort_img">
          <Image src={SortIcon} />
        </i>
        <span>{CONSTANTS?.SORT}</span>
      </div>
      {visibility && (
        <div className={STYLES?.OPTIONS_CONTAINER}>
          <ul className={STYLES?.SORT_OPTIONS}>
            <li
              className={STYLES?.OPTIONS}
              onClick={() => handleOptionClick("")}
            ></li>
            {options.map((option, index) => (
              <li
                key={index}
                className={`${STYLES?.ACTIVE_OPTIONS} ${
                  Sort === option.value ? STYLES?.SELECTED : ""
                }`}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
export default Sort;
