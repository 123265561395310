import React, { useEffect, useRef } from "react";
import "./Filter.scss";
import Image from "../Image/Image";
import FilterIcon from "../../../Assets/Filter.png";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import Checkbox from "../Checkbox/Checkbox";

const Filter = ({
  setVisibility,
  visibility,
  setSelectedOption,
  selectedOption,
  options,
}) => {
  const filterRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setVisibility(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setVisibility]);

  return (
    <div className="select" ref={filterRef}>
      <div
        className={
          visibility
            ? `selected-focused ${STYLES?.SELECTED_OPTIONS}`
            : STYLES?.SELECTED_OPTIONS
        }
        onClick={(e) => {
          setVisibility(!visibility);
        }}
      >
        <i className={STYLES?.FIILTER_IMG}>
          <Image src={FilterIcon} />
        </i>
        <span>{CONSTANTS?.FILTER}</span>
      </div>
      {visibility && (
        <div className={STYLES?.OPTIONS_CONTAINER}>
          <Checkbox
            options={options}
            selectedOptions={selectedOption}
            setSelectedOptions={setSelectedOption}
          />
        </div>
      )}
    </div>
  );
};
export default Filter;
