import React from "react";
import Container from "../Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import TextArea from "../../Molecules/TextArea/TextArea";
import Comments from "../Comments";
import "./CommentSection.scss";

const CommentSection = ({ setComment, comments, textAreaDisabled = false }) => {
  const onChange = (e) => {
    setComment(e.target.value);
  };
  return (
    <Container className={STYLES?.COMMENT_SECTION}>
      {comments?.length > 0 && <Comments data={comments} />}
      <TextArea
        onTextChange={onChange}
        placeholder={CONSTANTS?.COMMENT_TEXT_AREA}
        textAreaTitle={CONSTANTS?.ADD_COMMENT}
        disabled={textAreaDisabled}
      />
    </Container>
  );
};

export default CommentSection;
