import React from "react";
import { STYLES } from "../../../Helpers/Constants";
import StatusTag from "../../Atoms/StatusTag/StatusTag";
import { AddInvoice, DeleteButton, EditButton } from "./ChildComponents";
import DotsMenu from "../../Atoms/DotsMenu/DotsMenu";

// const StatusCell = ({ row }) => {
//   let backgroundColor, color, statusBorder;

//   switch (row.status) {
//     case "accepted":
//       backgroundColor = "#D1FAE5";
//       color = "#065F46";
//       statusBorder = "2px solid green";
//       break;
//     case "Accepted":
//       backgroundColor = "#D1FAE5";
//       color = "#065F46";
//       statusBorder = "2px solid green";
//       break;
//     case "Invited":
//       backgroundColor = "#FEF3C7";
//       color = " #92400E";
//       statusBorder = "2px solid red";
//       break;
//     case "invited":
//       backgroundColor = "#FEF3C7";
//       color = "#92400E";
//       statusBorder = "2px solid red";
//       break;
//     case "paid":
//       backgroundColor = " #D1FAE5";
//       color = "#065F46";
//       statusBorder = "2px solid green";
//       break;
//     case "unpaid":
//       backgroundColor = "#FEF3C7";
//       color = " #92400E";
//       statusBorder = "2px solid red";
//       break;
//     default:
//       backgroundColor = "gray";
//       break;
//   }

//   const cellStyle = {
//     padding: "10px",
//     textAlign: "center",
//     display: "flex",
//     gap: "0.5rem",
//     textTransform: "capitalize",
//     borderRadius: "4px",
//     padding: "2px 12px 2px 8px",
//     backgroundColor: backgroundColor,
//     color: color,
//   };

//   const dot = {
//     border: statusBorder,
//     borderRadius: "50%",
//     height: "2px",
//     width: "2px",
//     backgroundColor: color,
//   };

//   const Statusdot = {
//     display: "flex",
//     alignItems: "center",
//     color: color,
//   };

//   return (
//     <div style={cellStyle}>
//       <div style={Statusdot}>
//         <div style={dot}></div>
//       </div>
//       {row.status}
//     </div>
//   );
// };

// export default StatusCell;

export const columns = (
  setDeletePopUp,
  setEditPopUp,
  setIsAddInvoice,
  setRowID,
  setRowData
) => {
  const options = (row) => {
    return [
      <EditButton
        row={row}
        setRowData={setRowData}
        setEditPopUp={setEditPopUp}
      />,
      <DeleteButton
        row={row}
        setRowID={setRowID}
        setDeletePopUp={setDeletePopUp}
      />,
      <AddInvoice
        row={row}
        setRowID={setRowID}
        setIsAddInvoice={setIsAddInvoice}
      />,
    ];
  };

  return [
    {
      name: "Name",
      selector: (row) => row?.name,
      paddingLeft: "50px",
      maxWidth: "180px",
      cell: (row) => {
        return <div style={{ marginLeft: "20px" }}>{row.name}</div>;
      },
    },
    {
      name: "Email address",
      selector: (row) => row?.email,
      maxWidth: "250px",
      minWidth: "180px",
    },

    {
      name: "Created on ",
      selector: (row) => {
        const date = new Date(row?.created_at);
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      },

      maxWidth: "130px",
    },
    {
      name: "Payment method",
      cell: (row) => (
        <div className={STYLES?.PAYMENT_METHOD_CELL}>{row?.payment_method}</div>
      ),

      maxWidth: "180px",
    },

    {
      name: "Status",
      id: "status",
      cell: (row) => <StatusTag data={row} status={row?.status} isAdmin />,
      maxWidth: "180px",
    },
    {
      name: "Action",
      cell: (row) => <DotsMenu options={options(row)} />,
      maxWidth: "150px",
      center: true,
    },
  ];
};
export const customStyles = {
  headCells: {
    style: {
      color: "#212936",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "32px",
    },
  },
  headRow: {
    style: {
      background: "#F9FAFB",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  },
};
