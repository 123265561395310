import React from "react";
import Container from "../../Atoms/Container/Container";
import "./LandingPageFooter.scss";
import { STYLES } from "../../../Helpers/Constants";
import logoWordWell from "../../../Assets/LandingPageAssets/logo-word-well.png";
import Border from "../../Atoms/Border/Border";
import BottomContent from "../BottomContent/BottomContent";
import RowSpace from "../../Atoms/RowSpace/RowSpace";

const LandingPageFooter = () => {
  const winHeight = window.innerHeight;

  return (
    <Container className={STYLES?.FOOTER_CONTAINER}>
      <Container className={STYLES?.FOOTER_CONTENT}>
        <Container className={STYLES?.LOGO_FOOTER}>
          <img src={logoWordWell} alt="logo" className={STYLES?.HEADER_LOGO2} />
          {/* <Container>
            <SocialLinks />
          </Container> */}
        </Container>
      </Container>
      <Container className="border-footer">
        <Border />
      </Container>
      <Container>
        <RowSpace margin={20} />
        <BottomContent />

        <RowSpace margin={winHeight === 1024 ? 240 : 120} />
      </Container>
    </Container>
  );
};

export default LandingPageFooter;
