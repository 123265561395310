import "./UserForgotPassword.scss";
import { STYLES } from "../../../Helpers/Constants";
import Container from "../../Atoms/Container/Container";
import UserLoginBackground from "../../Atoms/UserLoginBackground";
import ForgotPasswrord from "../ForgotPassword/ForgotPasswrord";
import { ResetPasswordEmail } from "../../../Helpers/Functions";
const UserForgotPassword = () => {
  return (
    <Container className={STYLES?.FORGORT_PASSWORD_USER}>
      <ForgotPasswrord ResetPassword={ResetPasswordEmail}/>
      <UserLoginBackground />
    </Container>
  );
};

export default UserForgotPassword;
