import React, { useEffect, useState } from "react";
import "./UserSetting.scss";
import SettingAccount from "../../Molecules/SettingAccount/SettingAccount";
import SettingPassword from "../../Molecules/SettingPasword/SettingPassword";
import NameChangePopUp from "../../Molecules/NameChangePopUp/NameChangePopUp";
import { UserSettingSideBar } from "../../../Helpers/SettingSidebarItems";
import BillingSetting from "../../Molecules/BillingSetting/BillingSetting";
import { allPaymentMethods } from "../../../Helpers/Functions";
import { STYLES } from "../../../Helpers/Constants";
const body = {
  account: (props) => <SettingAccount {...props} />,
  password: (props) => <SettingPassword {...props} />,
  billing: (props) => <BillingSetting {...props} />,
};

function UserSetting() {
  const [activeMenu, setActiveMenu] = useState("account");
  const [showPopUp, setShowPopUp] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState({});
  const [userPaymentMethod, setUserPaymentMethod] = useState(null);
  const showChangePopUp = () => {
    setShowPopUp(true);
  };
  const hidePopUpForm = () => {
    setShowPopUp(false);
  };
  const handleOptionChange = (option) => {
    setActiveMenu(option);
  };
  useEffect(() => {
    const paymentMethod = localStorage.getItem("payment_method");
    setUserPaymentMethod(paymentMethod);
  }, []);
  useEffect(() => {
    userPaymentMethod === "quickbooks" &&
      allPaymentMethods().then((response) => {
        setPaymentMethods(response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filteredUserSettingSideBar =
    userPaymentMethod === "quickbooks"
      ? UserSettingSideBar.slice(0, 2)
      : UserSettingSideBar;

  return (
    <>
      {!showPopUp ? (
        <div className={STYLES?.SETTING_LAYOUT}>
          <div className={STYLES?.SETTING_LEFT_BAR}>
            <div className={STYLES?.SETTING_LEFT_BAR_CHILD}>
              {filteredUserSettingSideBar?.map((item) => (
                <p
                  key={item.label}
                  className={`${
                    item.label === activeMenu ? "active" : "option"
                  } ${STYLES?.SETTING_LEFT_BAR_ITEMS}`}
                  onClick={() => handleOptionChange(item.label)}
                >
                  <img
                    src={
                      item.label === activeMenu
                        ? item.icon.activeIcon
                        : item.icon.inactiveIcon
                    }
                    className="setting-left-icon"
                    alt={item.label}
                  />
                  {item.label === "account" ? "Your Profile" : item.label}
                </p>
              ))}
            </div>
          </div>

          <div className={STYLES?.SETTING_MAIN_CONTENT}>
            <div className={STYLES?.SETTING_MAIN_CONTENT_BODY}>
              {body[activeMenu]({ onClicked: showChangePopUp, paymentMethods })}
            </div>
          </div>
        </div>
      ) : (
        <NameChangePopUp hidePopUpForm={hidePopUpForm} />
      )}
    </>
  );
}

export default UserSetting;
