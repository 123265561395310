import User from "../Assets/SideBarImages/Users.svg";
import Order_Management from "../Assets/SideBarImages/order_management.svg";
import Billings from "../Assets/SideBarImages/Billing.svg";
import Settings from "../Assets/SideBarImages/Setting.svg";
import ActiveUser from "../Assets/SideBarImages/ActiveUsers.svg";
import Active_Order_Management from "../Assets/SideBarImages/ActiveOrderManagement.svg";
import ActiveBilling from "../Assets/SideBarImages/ActiveBilling.svg";
import ActiveSetting from "../Assets/SideBarImages/ActiveSetting.svg";
import { ROUTES, CONSTANTS } from "./Constants";
export const AdminSideBar = [
  {
    icon: { activeIcon: ActiveUser, inactiveIcon: User },
    path: ROUTES?.USER_MANAGEMENT,
    label: CONSTANTS?.USER_MANAGEMENT,
  },
  {
    icon: {
      activeIcon: Active_Order_Management,
      inactiveIcon: Order_Management,
    },
    path: ROUTES?.ORDER_MANAGEMENT,
    label: CONSTANTS?.ORDER_MANAGEMENT,
  },
  {
    icon: { activeIcon: ActiveBilling, inactiveIcon: Billings },
    path: ROUTES?.BILLING,
    label: CONSTANTS?.BILLING,
  },
  // {
  //   icon: { activeIcon: ActiveSetting, inactiveIcon: Settings },
  //   path: ROUTES?.SETTING,
  //   label: CONSTANTS?.SETTING,
  // },
];
export const UserSideBar = [
  {
    icon: {
      activeIcon: Active_Order_Management,
      inactiveIcon: Order_Management,
    },
    path: ROUTES?.ORDER,
    label: CONSTANTS?.MY_ORDER,
  },
  {
    icon: { activeIcon: ActiveBilling, inactiveIcon: Billings }, 
    path: ROUTES?.USER_BILLING,
    label: CONSTANTS?.BILLING,
  },
  {
    icon: { activeIcon: ActiveSetting, inactiveIcon: Settings }, 
    path: ROUTES?.USER_SETTING,
    label: CONSTANTS?.SETTING,
  },
];
export const breadcrumbsAdmin = [
  {
    key: CONSTANTS?.USER_MANAGEMENT,
    label: CONSTANTS?.USER_MANAGEMENT,
    link: ROUTES?.USER_MANAGEMENT,
  },
  {
    key: CONSTANTS?.ORDER_MANAGEMENT,
    label: CONSTANTS?.ORDER_MANAGEMENT,
    link: ROUTES?.ORDER_MANAGEMENT,
  },
  {
    key: CONSTANTS?.ORDER_DETAILS,
    label: CONSTANTS?.ORDER_DETAILS_LABEL,
    link: `${ROUTES?.ORDER_MANAGEMENT}${ROUTES?.ORDER_DETAILS}`,
  },
  { key: CONSTANTS?.BILLING, label: CONSTANTS?.BILLING, link: ROUTES?.BILLING },
  { key: CONSTANTS?.SETTING, label: CONSTANTS?.SETTING, link: ROUTES?.SETTING },
];
export const breadcrumbsUser = [
  { key: CONSTANTS?.MY_ORDER, label: CONSTANTS?.MY_ORDER, link: ROUTES?.ORDER },
  {
    key: CONSTANTS?.MY_ORDER,
    label: CONSTANTS?.ORDER_DETAILS_LABEL,
    link: `${ROUTES?.ORDER}${ROUTES?.CREATE_NEW_ORDER_FORM}`,
  },
  {
    key: CONSTANTS?.MY_ORDER,
    label: CONSTANTS?.ORDER_DETAILS_KEY,
    link: `${ROUTES?.ORDER}${ROUTES?.CREATE_NEW_ORDER}`,
  },
  {
    key: CONSTANTS?.BILLING,
    label: CONSTANTS?.BILLING,
    link: ROUTES?.USER_BILLING,
  },
  {
    key: CONSTANTS?.SETTING,
    label: CONSTANTS?.SETTING,
    link: ROUTES?.USER_SETTING,
  },
];
