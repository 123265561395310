import React from "react";
import { STYLES } from "../../../Helpers/Constants";
import "./LandingPageButton.scss";

const LandingPageButton = ({ className, buttonText, onClick }) => {
  const buttonClass = `${STYLES?.BUTTON_CONTAINER} ${className}`;
  return (
    <button className={buttonClass} onClick={onClick}>
      {buttonText}
    </button>
  );
};

export default LandingPageButton;
