import React, { useState, useEffect } from "react";
import SearchResult from "../../Molecules/SearchResult";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import "./UserManagement.scss";
import DeleteConfirmation from "../DeleteConfirmation";
import DataTable from "react-data-table-component";
import DealsTableTitleRow from "../../Atoms/DealsTableTitleRow";
import Container from "../../Atoms/Container/Container";
import UserManagementLogin from "../../Molecules/UserMangementLogin/UserManagementLogin";
import CreateNewUser from "../../Molecules/CreateNewUser/CreateNewUser";
import UserEditForm from "../../Molecules/UserEditForm/UserEditForm";
import { columns, customStyles } from "./UserManagementColumns";
import CustomPagination from "../../Molecules/CustomPagination/CustomPagination";
import {
  DeleteData,
  fetchDeals,
  formatFilter,
} from "../../../Helpers/Functions";
import { BASE_URL, ERROR_MESSAGES } from "../../../Helpers/APIUrls";
import fetchWithHeaders from "../../../FetchIntercepter";
import ButtonPrimary from "../../Atoms/ButtonPrimary/ButtonPrimary";
import {
  PaymentMethods,
  UserManagementoptions,
} from "../../../Helpers/FilterOptions";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
import BoldBlackHeading from "../../Atoms/BoldBlackHeading/BoldBlackHeading";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import CreateInvoiceForm from "../CreateInvoiceForm";
const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};
const limit = 6;

const headingArr = [
  CONSTANTS?.NAME,
  CONSTANTS?.EMAIL_ADRESS,
  CONSTANTS?.CREATED_ON,
  CONSTANTS?.PAYMENT_METHOD,
  CONSTANTS?.STATUS,
  CONSTANTS?.ACTION,
];

const UserManagement = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [count, setCount] = useState(1);
  const [dealsData, setDealsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("");
  const [rowID, setRowID] = useState("");
  const [rowData, setRowData] = useState("");
  const [isDeletePopUp, setDeletePopUp] = useState(false);
  const [isEditPopUp, setEditPopUp] = useState(false);
  const [isAddInvoice, setIsAddInvoice] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [sortVisibilty, setsortVisibilty] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [noRows, setNoRows] = useState(0);

  useEffect(() => {
    const formattedFilter = formatFilter(filter);

    fetchDeals({
      limit,
      currentPage,
      search,
      sort,
      filter: formattedFilter,
      setDealsData,
      setLoading,
      setCount,
    });
  }, [search, filter, sort, currentPage]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const ConfirmDelete = () => {
    DeleteData(rowID).then((res) => {
      if (res) {
        setSearch("");
        const filterData = sortedData.filter((item) => item.id !== rowID);
        setSortedData(filterData);
      }
    });

    setDeletePopUp(false);
  };

  const confirmEdit = async (data = { rowData }) => {
    try {
      const accessToken = getAccessToken();
      const response = await fetchWithHeaders(`${BASE_URL}/${rowData?.id}`, {
        method: "PUT",
        Authorization: `Bearer ${accessToken}`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payment_method: data?.SelectOption.value,
          name: data?.Name,
        }),
      });
      if (response?.ok) {
        setEditPopUp(false);
        fetchDeals({
          limit,
          currentPage,
          search,
          sort,
          setDealsData,
          setLoading,
          setCount,
        });
        return await response.json();
      } else {
        throw new Error(ERROR_MESSAGES?.AUTH_FAILED + response.statusText);
      }
    } catch (error) {
      throw new Error(ERROR_MESSAGES?.ERROR_AUTH + error.message);
    }
  };

  const HandleCancel = () => {
    setDeletePopUp(false);
  };

  const hidePopUpForm = () => {
    setEditPopUp(false);
    setShowPopup(false);
  };

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  const handleNewUser = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    if (dealsData && dealsData.length > 0) {
      setSortedData([...dealsData]);
    }
  }, [dealsData]);

  useEffect(() => {
    if (newUser) {
      setSortedData([newUser, ...dealsData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUser]);

  useEffect(() => {
    setNoRows(sortedData.length);
  }, [sortedData]);

  return (
    <Container className={STYLES?.USER_MANAGEMENT_SCREEN}>
      {!showPopup ? (
        isDeletePopUp ? (
          <DeleteConfirmation
            type={"user"}
            setDeletePopUp={setDeletePopUp}
            setRowID={setRowID}
            ConfirmDelete={ConfirmDelete}
            HandleCancel={HandleCancel}
          />
        ) : isEditPopUp ? (
          <UserEditForm
            confirmEdit={confirmEdit}
            rowData={rowData}
            HandleCancelUpdateForm={hidePopUpForm}
            HandleCrossUser={hidePopUpForm}
          />
        ) : isAddInvoice ? (
          <CreateInvoiceForm
            setShowPopup={setIsAddInvoice}
            onAdded={() => {}}
            userID={rowID}
          />
        ) : (
          <>
            <Container className={STYLES?.MainContent}>
              <BoldBlackHeading text={CONSTANTS?.USER_MANAGEMENT} />
              <Container className={STYLES?.SEARCH_CONTENT}>
                <SearchResult
                  setShowPopup={setShowPopup}
                  onSearch={handleSearch}
                  setSearch={setSearch}
                  setSort={setSort}
                  setFilter={setFilter}
                  filter={filter}
                  sortingValue={true}
                  count={count}
                  visibility={visibility}
                  setVisibility={setVisibility}
                  options={{
                    Status: UserManagementoptions,
                    "Payment Method": PaymentMethods,
                  }}
                  sortVisibilty={sortVisibilty}
                  setsortVisibilty={setsortVisibilty}
                  paymentOptions={true}
                  showFilter={true}
                  showSort={true}
                />

                <Container>
                  {count !== 0 && (
                    <ButtonPrimary
                      buttonText={CONSTANTS?.CREATE_NEW_USER_BUTTON}
                      onClick={() => setShowPopup(true)}
                    />
                  )}
                </Container>
              </Container>
              <RowSpace margin={32} />
              {!loading ? (
                <>
                  {dealsData?.length > 0 ? (
                    <div className={STYLES?.CUSTOM_TABLE}>
                      <DataTable
                        customStyles={customStyles}
                        noHeader={true}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={"48vh"}
                        columns={columns(
                          setDeletePopUp,
                          setEditPopUp,
                          setIsAddInvoice,
                          setRowID,
                          setRowData
                        )}
                        data={sortedData}
                        pagination
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        paginationComponent={(props) => (
                          <CustomPagination
                            {...props}
                            currentPage={currentPage}
                            limit={limit}
                            noRows={noRows}
                            count={count}
                            setCurrentPage={setCurrentPage}
                            onChangePage={handleChangePage}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <>
                      <DealsTableTitleRow list={headingArr} />
                      <CreateNewUser
                        setShowPopup={handleNewUser}
                        buttonText={CONSTANTS?.CREATE_NEW_USER_BUTTON}
                        text={CONSTANTS?.CREATE_NEW_USER}
                        text1={CONSTANTS?.NO_USER}
                      />
                    </>
                  )}
                </>
              ) : (
                <CustomLoader />
              )}
            </Container>
          </>
        )
      ) : (
        <UserManagementLogin
          hidePopUpForm={hidePopUpForm}
          newData={setNewUser}
        />
      )}
    </Container>
  );
};
export default UserManagement;
