import React from "react";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import "./HeaderLogo.scss";

const HeaderLogo = ({ imageSrc }) => {
  return (
    <Container>
      <img src={imageSrc} alt="Header Logo" className={STYLES?.HEADER_LOGO} />
    </Container>
  );
};

export default HeaderLogo;
