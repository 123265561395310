export const ViewButton = ({ data }) => {
  const isAbsoluteUrl = data && /^https?:\/\//i.test(data.qb_invoice);
  const linkEnabled = data && data.qb_invoice; // Check if qb_invoice exists and is not null

  return (
    <>
      {linkEnabled ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={isAbsoluteUrl ? data.qb_invoice : `http://${data.qb_invoice}`}
        >
          View
        </a>
      ) : (
        <span style={{ color: "gray", cursor: "not-allowed" }}>View</span>
      )}
    </>
  );
};
