import React from "react";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import { Text } from "../Typography/Text";
import "./BoldBlackHeading.scss";

const BoldBlackHeading = ({ text }) => {
  return (
    <Container>
      <Text variant="h5" className={STYLES?.BLACK_BOLD_HEADING}>
        {text}
      </Text>
    </Container>
  );
};

export default BoldBlackHeading;
