import { Outlet, Navigate } from "react-router-dom";
import Layout from "../Components/Molecules/Layout/Layout";
import { ROUTES } from "./Constants";

const AdminRoutes = () => {
  const token = localStorage.getItem("accessToken");
  const admin =
    localStorage.getItem("userType") &&
    localStorage.getItem("userType") === "admin";

  return token && admin ? (
    <Layout isAdmin>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={ROUTES?.ADMIN_LOGIN} />
  );
};

export default AdminRoutes;
