import Select from "react-select";
import React from "react";
import Container from "../Container/Container";
import { Text } from "../Typography/Text";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
const CustomSelect = ({
  options,
  label,
  onChange,
  onBlur,
  value,
  ref,
  styles,
  errors,
  grouped = false,
  isRequired=false,
}) => {
  const hasError = errors?.SelectOption;
  const SelectComponent = grouped ? Select.Group : Select;

  const handleChange = (selectedOption) => {
    onChange(selectedOption);
  };
  return (
    <Container>
      {label && <label className={isRequired ? "required" : ""}>{label}</label>}
      <SelectComponent
        options={options}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        ref={ref}
        styles={styles}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary75: "05966F",
            primary50: "05966F",
            primary: "05966F",
          },
        })}
        components={{ IndicatorSeparator: () => null }}
      />
      
      {hasError && (
        <Text variant="p" className={STYLES?.ERROR_MESSAGE}>
          {CONSTANTS?.SELECT_ERROR}
        </Text>
      )}
    </Container>
  );
};

export default CustomSelect;
