import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";

/* eslint-disable no-useless-escape */

/* eslint-disable no-useless-escape */

// // eslint-disable-next-line react-hooks/exhaustive-deps

export const CustomOrderFormSchema = yup.object().shape({
  Name: yup.string().required("Name is required"),
  Phone: yup.string().required("Phone Number is required"),
  Email: yup
    .string()
    .email(CONSTANTS?.CORRECT)
    .required(CONSTANTS?.EMAIL_VALUE)
    .max(50, CONSTANTS?.EMAIL_MAX)
    .min(4, CONSTANTS?.EMAIL_MIN),
  ProjectDetails: yup.string(),
});
export const defaultValues = {
  Name: "",
  Phone: "",
  Email: "",
  ProjectDetails: "",
};
