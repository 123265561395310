import { useEffect } from "react";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import Image from "../../Atoms/Image/Image";
import SearchIcon from "../../../Assets/Search.png";
import Sort from "../../Atoms/Sort/Sort";
import Filter from "../../Atoms/Filter/Filter";
import "./SearchResult.scss";
import Container from "../../Atoms/Container/Container";
import { debounce } from "../../../Helpers/Functions";

const SearchResult = ({
  onSearch,
  setSort,
  setSearch,
  setFilter,
  filter,
  visibility,
  setVisibility,
  options,
  sortVisibilty,
  setsortVisibilty,
  showFilter,
  showSort,
}) => {
  const sorting = "";
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    debounce([setSearch(newValue), onSearch(newValue)], 300);
  };

  useEffect(() => {
    setSort(sorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container className={STYLES?.SEARCH_CONTAINER}>
      <Container className={STYLES?.SEARCH}>
        <Container className={STYLES?.SEARCH_FIELD}>
          <input
            className={STYLES?.INPUTFILED}
            placeholder={CONSTANTS?.SEARCH}
            maxLength={20}
            onChange={handleInputChange}
          />
          <Image src={SearchIcon} className={STYLES?.SEARCH_ICON} />
        </Container>
        {showSort && (
          <Sort
            sort={sorting}
            setSort={setSort}
            visibility={sortVisibilty}
            setVisibility={setsortVisibilty}
          />
        )}

        {showFilter && (
          <Filter
            selectedOption={filter}
            setSelectedOption={setFilter}
            visibility={visibility}
            setVisibility={setVisibility}
            options={options}
          />
        )}
      </Container>
    </Container>
  );
};

export default SearchResult;
