import React, { useContext, useEffect, useState } from "react";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import Container from "../../Atoms/Container/Container";
import logo from "../../../Assets/logo.svg";
import "./OtpVerification.scss";
import Image from "../../Atoms/Image";
import { ResetPasswordEmail, updatePassword } from "../../../Helpers/Functions";
import AppContext from "../../../Context/AppContext";
import { errorToast, successToast } from "../../../Helpers/useToast";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import { Text } from "../../Atoms/Typography/Text";
import OtpInput from "react-otp-input";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";

const OtpVerification = ({ password }) => {
  const { isLoading, setIsLoading } = useContext(AppContext);
  const [isGeneratingOTP, setIsGeneratingOTP] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [otp, setOtp] = useState("");
  const get_user_email = localStorage.getItem("email_user");

  const onSubmit = async () => {
    setIsLoading(true);

    const res = await updatePassword(password, otp, get_user_email);
    if (res.status === 200) {
      successToast("Password updated successfully");
      localStorage.clear();
      setIsLoading(false);
      localStorage.setItem("NewPassword", true);
      window.location.href = "/";
    } else {
      errorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const generateOtp = async () => {
    setIsGeneratingOTP(true);
    setCountdown(30);
    setOtp("");
    const resData = await ResetPasswordEmail(get_user_email);
    if (resData.status !== 200) {
      errorToast(CONSTANTS?.COMMON_ERROR);
      setIsGeneratingOTP(false);
    }
  };

  useEffect(() => {
    setIsGeneratingOTP(true);
  }, []);

  useEffect(() => {
    let interval;
    if (isGeneratingOTP) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(interval);
            setIsGeneratingOTP(false);
            return 0;
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isGeneratingOTP]);

  useEffect(() => {
    if (otp.length === 6) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return (
    <Container className={STYLES?.CREATE_NEWPASSWORD_CONTAINER}>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <Container className={STYLES?.OTP_INNER_CONTAINER}>
            <Container className={STYLES?.FORGOT_PASSWORD_HEADER}>
              <Image src={logo} alt="Logo" className={STYLES.LOGO_FORGET} />
              <h2 className={STYLES?.CREATE_PASSWORD_TITLE}>
                {CONSTANTS?.OTP_VERIFICATION}
              </h2>
              <RowSpace margin={16} />
              <Text className={STYLES?.LIGHT_GREY_TEXT}>
                {CONSTANTS?.OTP_ENTER}
              </Text>
              <Text className={STYLES?.EMAIL_TEXT}>{get_user_email}</Text>
            </Container>
            <RowSpace margin={32} />
            <Container>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                containerStyle={"containerStyle"}
                skipDefaultStyles
                inputStyle={"inputStyle"}
                renderInput={(props) => (
                  <input {...props} disabled={countdown === 0} />
                )}
              />
              <RowSpace margin={34} />
              <Text className={STYLES?.LIGHT_GREY_TEXT}>
                {CONSTANTS?.CODE_EXPIRE_TEXT}
                <span className={STYLES?.LIGHT_BLACK_TEXT}>
                  {countdown} sec
                </span>
              </Text>
              <RowSpace margin={16} />
              <Text className={STYLES?.GREY_NORMAL_TEXT}>
                {CONSTANTS?.CODE_NOT_RECEIVED}
                <span
                  className={`${STYLES?.RESEND_BUTTON} ${
                    isGeneratingOTP && STYLES?.RESEND_BUTTON_DISABLED
                  }`}
                  onClick={!isGeneratingOTP ? generateOtp : undefined}
                >
                  {CONSTANTS?.RESEND}
                </span>
              </Text>
            </Container>
          </Container>
        </>
      )}
    </Container>
  );
};

export default OtpVerification;
