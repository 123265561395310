import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import typeWriter from "../../../Assets/LandingPageAssets/Typist.png";
import "./BriefIntro.scss";
import { Text } from "../../Atoms/Typography/Text";
import RowSpace from "../../Atoms/RowSpace/RowSpace";

const BriefIntro = () => {
  return (
    <Container className={STYLES?.INTRO_BACKGROUND}>
      <Container className={"intro-background-content"}>
        <Container className={STYLES?.INTRO_TEXT}>
          <Container className={STYLES?.GREEN_STYLED_MED_TEXT}>
            <Text>{CONSTANTS?.WHY_HUMAN_CRAFTED}</Text>
          </Container>
          <RowSpace margin={16} />
          <Text className={STYLES?.SMALL_WHITE_TEXT}>
            {CONSTANTS?.LONG_INTRO}
          </Text>
        </Container>
        <Container className={"intro-image-container"}>
          <img src={typeWriter} alt="logo" className={STYLES?.INTRO_IMG} />
        </Container>
      </Container>
    </Container>
  );
};

export default BriefIntro;
