import { useState } from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "./CustomOrderForm.scss";
import { CustomOrderFormSchema, defaultValues } from "./CustomOrderFormSchema";

import InputField from "../../Atoms/InputField/InputField";
import Button from "../../Atoms/Button/Button";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import { useNavigate } from "react-router-dom";
import Border from "../../Atoms/Border";
import { CreateCustomOrder } from "../../../Helpers/Functions";
import { successToast } from "../../../Helpers/useToast";

const CustomOrderForm = ({ setData }) => {
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(CustomOrderFormSchema),
  });

  const formClass = ErrorMessage
    ? STYLES?.USER_MANAGEMENT_LOGIN_FORM_ERROR
    : STYLES?.CREATE_NEW_ORDER_FORM;
  const inputFieldClass = ErrorMessage
    ? STYLES?.INPUT_FORM_FIELD_ERROR_USER_MANAGEMENT
    : STYLES?.USER_MANAGEMENT_INPUT_FIELD;

  // const onSubmit = (data) => {
  //   console.log("data", data);
  //   setData({
  //     name: data?.Name,
  //     email: data?.Email,
  //     phone: data?.Phone,
  //     projectDetails: data?.ProjectDetails,
  //   });
  // };
  const onSubmit = async (data) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      const responseData = await CreateCustomOrder({
        name: data?.Name,
        phone: data?.Phone,
        email: data?.Email,
        projectDetails: data?.ProjectDetails,
      });
      if (responseData) {
        successToast("Custom Order Form submitted successfully");
        navigate(ROUTES?.ORDER);
      }
    } catch (error) {
      console.log(error, "errrrr");
    }
  };

  const HandleCancel = () => {
    navigate(ROUTES?.ORDER);
  };

  return (
    <Container className={STYLES?.ORDER_FORM_CONTAINER}>
      <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
        <Container className="width-40">
          <>
            <RowSpace margin={20} />
            <InputField
              name={CONSTANTS?.NAME}
              type="text"
              label={CONSTANTS?.NAME}
              register={register}
              field={CONSTANTS?.NAME}
              errors={errors}
              maxLength={50}
              setErrorMessage={setErrorMessage}
              className={inputFieldClass}
              isRequired
            />
          </>

          <RowSpace margin={20} />
          <InputField
            name={CONSTANTS?.PHONE}
            type={CONSTANTS?.TEXT}
            label={CONSTANTS?.PHONE_NUMBER}
            register={register}
            maxLength={50}
            field={CONSTANTS?.PHONE}
            errors={errors}
            setErrorMessage={setErrorMessage}
            className={inputFieldClass}
            isRequired={true}
          />
          <RowSpace margin={20} />
          <InputField
            name={CONSTANTS?.EMAIL}
            field={CONSTANTS?.EMAIL}
            type={CONSTANTS?.EMAIL_TYPE}
            placeholder={CONSTANTS?.EMAIL_PLACEHOLDE5}
            register={register}
            errors={errors}
            maxLength={50}
            label={CONSTANTS?.EMAIL_ADDRESS}
            onChange={(e) => {
              setValue(CONSTANTS?.EMAIL, e.target.value);
            }}
            className={inputFieldClass}
            isRequired={true}
          />

          <RowSpace margin={20} />
          <label htmlFor={CONSTANTS?.TEXTAREA}>
            {CONSTANTS?.PROJECT_DETAILS}
          </label>
          <Controller
            name={CONSTANTS?.PROJECTDETAILS}
            control={control}
            defaultValue={defaultValues[CONSTANTS?.PROJECT_DETAILS]}
            render={({ field }) => (
              <textarea
                {...field}
                className={STYLES?.TEXTAREA_CREATE_NEW_ORDER}
              />
            )}
          />
        </Container>
        <RowSpace margin={48} />

        <Border />

        <Container className="width-40"></Container>
        <Container className={STYLES?.CREATE_ORDER_FORM_BUTTON}>
          <Button
            type={CONSTANTS?.BUTTON_TYPE}
            className={STYLES?.SEND_BUTTON}
            buttonText={CONSTANTS?.NEXT}
            disabled={isSubmitting}
          />
          <Button
            type="button"
            className={STYLES?.CANCEL_BUTTON}
            buttonText={CONSTANTS?.CANCEL}
            onClick={HandleCancel}
          />
        </Container>
      </form>
      {ErrorMessage && (
        <p className={STYLES?.Login_Error_Message}>{ErrorMessage}</p>
      )}
    </Container>
  );
};

export default CustomOrderForm;
