import React from "react";
import { STYLES } from "../../../Helpers/Constants";
import "./PrimaryButton.scss";

const PrimaryButton = ({
  buttonText,
  onClick = () => {},
  width,
  isCancel = false,
  download = false,
  downloadLink,
  ...rest
}) => {
  const handleClick = () => {
    if (download) {
      const link = document.createElement("a");
      link.href = downloadLink;
      link.setAttribute("download", downloadLink); //instead of downloadLink, dummyFileName comes here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      onClick();
    }
  };

  return (
    <button
      className={
        isCancel ? "create-primary-button-white" : STYLES.CREATE_PRIMARY_BUTTON
      }
      onClick={handleClick}
      {...rest}
    >
      {buttonText}
    </button>
  );
};

export default PrimaryButton;
