import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";

export const schema = yup.object().shape({
  Name: yup
    .string()
    .required(CONSTANTS?.NAME_MISSING)
    .max(50, CONSTANTS?.NAME_MAX)
    .min(4, "Name must be atleast 4 characters long"),
  Email: yup
    .string()
    .email(CONSTANTS?.CORRECT)
    .required(CONSTANTS?.EMAIL_VALUE)
    .max(50, CONSTANTS?.EMAIL_MAX)
    .min(4, CONSTANTS?.EMAIL_MIN),
    Password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(50, "Password must be at most 50 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("Password"), null], "Passwords must match")
    .required("Confirm Password is required"),
 
});

export const defaultValues = {
  Name: "",
  Email: "",
  Password: "",
};
