import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";
export const schema = yup.object().shape({
  Email: yup
    .string()
    .email(CONSTANTS?.CORRECT)
    .required(CONSTANTS?.EMAIL_VALUE)
    .max(100, CONSTANTS?.EMAIL_MAX)
    .min(4, CONSTANTS?.EMAIL_MIN),

  Name: yup.string().required(CONSTANTS?.NAME),

  SelectOption: yup
    .object()
    .shape({
      value: yup.string().required("Select an option"),
      label: yup.string().required("Select an option label"),
    })
    .required("Select an option"),
});

export const defaultValues = {
  Email: "",
  Name: "",
  SelectOption: "",
};
