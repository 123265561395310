import DownloadCell from "../../Atoms/OrderDownloadColumn/DownloadCell";
import DotsMenu from "../../Atoms/DotsMenu/DotsMenu";
import StatusTag from "../../Atoms/StatusTag/StatusTag";
import { ViewButton } from "./ChildComponents";

export const columns = (navigate) => {
  const options = (row) => {
    return [<ViewButton row={row} navigate={navigate} />];
  };

  return [
    {
      name: "Name",
      selector: (row) => row?.business_name,
      maxWidth: "180px",
      cell: (row) => (
        <div style={{ marginLeft: "20px" }}>{row?.business_name}</div>
      ),
    },
    {
      name: "Order type",
      selector: (row) => row?.page_type,
      maxWidth: "250px",
      minWidth: "180px",
    },
    {
      name: "Date ",
      selector: (row) => {
        const date = new Date(row?.created_at);
        const options = { month: "2-digit", day: "2-digit", year: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);
        const formattedDateWithDashes = formattedDate.replace(/\//g, "-");
        return formattedDateWithDashes;
      },
      maxWidth: "130px",
    },
    {
      name: "Status",
      id: "status",
      cell: (row) => <StatusTag data={row} status={row?.order_status} />,
      maxWidth: "180px",
    },
    {
      name: "Download",
      selector: (row) => row,
      maxWidth: "140px",
      cell: (row) => <DownloadCell row={row} />,
    },

    {
      name: "Action",
      cell: (row) => <DotsMenu options={options(row)} />,
      maxWidth: "130px",
      center: true,
    },
  ];
};

export const customStyles = {
  headCells: {
    style: {
      color: "#212936",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "32px",
      textAlign: "right",
    },
  },
  headRow: {
    style: {
      background: "#F9FAFB",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      // paddingLeft: "20px",
    },
  },
};
