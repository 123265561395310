// export function TimeFormat(timeStr) {
//   console.log("Given Time", timeStr);
//   // Extract hours, minutes, and seconds from the time string
//   const [hours, minutes] = timeStr.split(":").map(parseFloat);

//   // Format the hours into 12-hour format
//   const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

//   // Determine if it's AM or PM
//   const period = hours < 12 ? "AM" : "PM";

//   // Format the time as "hours:minutes AM/PM"
//   const formattedTime = `${formattedHours}:${
//     minutes < 10 ? "0" : ""
//   }${minutes} ${period}`;

//   return formattedTime;
// }
export function TimeFormat(timeStr) {
  console.log("Given Time (GMT):", timeStr);

  // Convert the GMT time string to a Date object
  const [hours, minutes] = timeStr.split(":").map(Number);
  const date = new Date(Date.UTC(1970, 0, 1, hours, minutes));

  // Get the local time from the Date object
  const localHours = date.getHours();
  const localMinutes = date.getMinutes();

  // Format the hours into 12-hour format
  const formattedHours = localHours % 12 === 0 ? 12 : localHours % 12;

  // Determine if it's AM or PM
  const period = localHours < 12 ? "AM" : "PM";

  // Format the time as "hours:minutes AM/PM"
  const formattedTime = `${formattedHours}:${
    localMinutes < 10 ? "0" : ""
  }${localMinutes} ${period}`;

  return formattedTime;
}
