import React from "react";
import Container from "../../Atoms/Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import { Text } from "../../Atoms/Typography/Text";
import "./ProductSample.scss";

const ProductSample = ({ icon, productTitle, productDescription }) => {
  return (
    <Container className={STYLES?.PRODUCT_SAMPLE_CONTAINER}>
      <Container className={STYLES?.PRODUCT_SAMPLE_INNER_CONTAINER}>
        <img src={icon} alt="logo" />
        <Text className={STYLES?.PRODUCT_TITLE}>{productTitle}</Text>
      </Container>
      <Text className={STYLES?.PRODUCT_DETAILS_TEXT}>{productDescription}</Text>
      
    </Container>
  );
};

export default ProductSample;
