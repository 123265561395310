import React from "react";
import Container from "../Container/Container";
import "./Comments.scss";
import profile from "../../../Assets/profile.svg";
import { STYLES } from "../../../Helpers/Constants";
import Image from "../Image";
import { Text } from "../Typography/Text";
import { FormatDateCustom } from "../../../Helpers/FormatDateCustom";
import { TimeFormat } from "../../../Helpers/TimeFormat";

const Comments = ({ data }) => {
  return (
    <>
      {data.map((item, id) => {
        return (
          <Container className={STYLES?.COMMENTS_CONTAINER}>
            <Container className={STYLES?.COMMENT_PROFILE}>
              <Image src={profile} alt={"profile"} />
            </Container>
            <Container>
              <Container className={STYLES?.COMMENTS_USER_DETAILS}>
                <Text>{item?.comment_by_name}</Text>
                <Text>{FormatDateCustom(item?.created_at.split("T")[0])} </Text>
                <Text>|</Text>
                <Text>{TimeFormat(item?.updated_at.split("T")[1])}</Text>
              </Container>
              <Container className={STYLES?.COMMENTS_CONTENT}>
                <Text>{item?.comment}</Text>
              </Container>
            </Container>
          </Container>
        );
      })}
    </>
  );
};

export default Comments;
