import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";
// const re =
//   /^(((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+(\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?)|((ftp|http[s]?)\:\/\/(?:www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([\/?].*)?)$/gm;

/* eslint-disable no-useless-escape */
const re =
/^(((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+(\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?)|((ftp|https?):\/\/(?:www\.)?(?!.*(ftp|http|https|www))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([\/?].*)?)$/gm;

export const schema = yup.object().shape({
  InvoiceLink: yup
    .string()
    .required("Invoice Link is required")
    .matches(re, "Link is not valid"),

  Amount: yup
    .string()
    .required(CONSTANTS?.AMOUNT)
    .matches(/^\d+$/, "Amount must contain only digits"),
  User: yup
    .object()
    .shape({
      value: yup.string().required("Select an option"),
      label: yup.string().required("Select an option label"),
    })
    .required("Select an option"),

  Date: yup
    .date()
    .typeError("Please Select a Date")
    .required("Please Select a Date"),
});

export const secondarySchema = yup.object().shape({
  InvoiceLink: yup
    .string()
    .required("Invoice Link is required")
    .matches(re, "Link is not valid"),

  Amount: yup
    .string()
    .required(CONSTANTS?.AMOUNT)
    .matches(/^\d+$/, "Amount must contain only digits"),

  User: yup.string().optional(),

  Date: yup
    .date()
    .typeError("Please Select a Date")
    .required("Please Select a Date"),
});

export const defaultValues = {
  InvoiceLink: "",
  Amount: "",
  User: "",
  //   Date: "",
};
