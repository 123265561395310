import React, { useEffect } from "react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import InputField from "../../Atoms/InputField/InputField";
import { Text } from "../../Atoms/Typography/Text";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "../../Atoms/Image";
import CrossIcon from "../../../Assets/WhiteCross.svg";
import CustomSelect from "../../Atoms/CustomSelect/CustomSelect";
import { defaultValues, schema } from "./schema";

import ButtonSecondary from "../../Atoms/ButtonSecondary";

const options = [
  { value: "stripe", label: CONSTANTS?.STRIPE },
  { value: "quickbooks", label: CONSTANTS?.QUICK_BOOKS },
];

const UserEditForm = ({
  confirmEdit,
  HandleCancelUpdateForm,
  HandleCrossUser,
  rowData,
}) => {
  const [ErrorMessage, setErrorMessage] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      padding: 5,
      margin: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      border: errors?.["SelectOption"]
        ? "3px solid #C52A1A"
        : state.isFocused
        ? "1px solid #D1D5DB"
        : "1px solid #ced4da",
      ":hover": {
        border: errors?.["SelectOption"]
          ? "3px solid #C52A1A"
          : state.isFocused
          ? "1px solid #D1D5DB"
          : "1px solid #ced4da",
      },

      borderRadius: "5px",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#05966F" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  const formClass = ErrorMessage
    ? STYLES?.USER_MANAGEMENT_LOGIN_FORM_ERROR
    : STYLES?.User_Login_Form;
  const inputFieldClass = ErrorMessage
    ? STYLES?.INPUT_FORM_FIELD_ERROR_USER_MANAGEMENT
    : STYLES?.USER_MANAGEMENT_INPUT_FIELD;

  useEffect(() => {
    if (rowData) {
      const selectedPaymentOption = options.find(
        (option) => option.value === rowData?.payment_method
      );

      reset({
        Email: rowData?.email,
        Name: rowData?.name,
        SelectOption: selectedPaymentOption,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  return (
    <Container className={STYLES?.USER_MANAGEMENT_LOGIN_PAGE}>
      <Container className={STYLES?.USER_MANAGEMENT_POPUP}>
        <Container onClick={HandleCrossUser} className={STYLES?.CROSS_ICON}>
          <Image src={CrossIcon} />
        </Container>

        <Container className={STYLES?.LOGIN_USER_MANAGEMENT}>
          <Container>
            <Text variant="h5" className={STYLES?.USER_MANAGEMENT_LOGIN_TEXT}>
              {CONSTANTS?.UPDATE_WORLDWELL_USER}
            </Text>
            <Container>
              <form className={formClass} onSubmit={handleSubmit(confirmEdit)}>
                <InputField
                  name={CONSTANTS?.NAME}
                  type="text"
                  label={CONSTANTS?.NAME}
                  register={register}
                  field={CONSTANTS?.NAME}
                  errors={errors}
                  setErrorMessage={setErrorMessage}
                  className={inputFieldClass}
                />

                <InputField
                  label={CONSTANTS?.EMAIL_ADDRESS}
                  name={CONSTANTS?.EMAIL}
                  type={CONSTANTS?.EMAIL_TYPE}
                  field={CONSTANTS?.EMAIL}
                  register={register}
                  setErrorMessage={setErrorMessage}
                  errors={errors}
                  className={inputFieldClass}
                  disabled={true}
                />

                <Controller
                  name="SelectOption"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      SelectOption={value}
                      setErrorMessage={setErrorMessage}
                      errors={errors}
                      styles={customStyles}
                      showCheckbox={false}
                    />
                  )}
                />

                <Container className={STYLES?.USER_MANAGEMENT_BUTTON}>
                  <ButtonSecondary
                    type={CONSTANTS?.BUTTON_TYPE}
                    className={STYLES?.SEND_BUTTON}
                    buttonText={CONSTANTS?.SEND}
                  />
                  <ButtonSecondary
                    type="button"
                    className={STYLES?.CANCEL_BUTTON}
                    buttonText={CONSTANTS?.CANCEL}
                    onClick={HandleCancelUpdateForm}
                  />
                </Container>
                {ErrorMessage && (
                  <p className={STYLES?.ERROR_MESSAGE}>{ErrorMessage}</p>
                )}
              </form>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};
export default UserEditForm;
