import { Outlet, Navigate } from "react-router-dom";
import Layout from "../Components/Molecules/Layout/Layout";
import { ROUTES } from "./Constants";

const ClientRoutes = () => {
  const token = localStorage.getItem("accessToken");
  const client = localStorage.getItem("userType") === "user";

  return token && client ? (
    <Layout client>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={ROUTES?.USER_LOGIN} />
  );
};

export default ClientRoutes;
