import React from "react";
import Container from "../Container/Container";
import "./AccordionData.scss";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import Image from "../Image";
import PaperClip from "../../../Assets/PaperClip.svg";

const AccordianDataCell = ({ title, description }) => {
  return (
    <Container className={STYLES?.ACCORDION_DATA_CONTAINER}>
      <Container className={STYLES?.ACCORDION_DATA_INNER_CONTAINER}>
        <p className={STYLES?.ACCORDION_DATA_TITLE}>{title}</p>
        {description?.length !== 0 ? (
          Array.isArray(description) ? (
            <>
              {description?.map((des, index) => (
                <p key={index} className={STYLES?.ACCORDION_DATA}>
                  {des?.url || des}
                </p>
              ))}
            </>
          ) : (
            <p className={STYLES?.ACCORDION_DATA}>{description}</p>
          )
        ) : (
          "--"
        )}
      </Container>
    </Container>
  );
};

const AccordionData = ({ data }) => {
  const {
    content_requirements,
    page_type,
    business_name,
    website_url,
    existing_url,
    // PageBlogName,
    content_examples,
    keywords,
    additional_notes,
    price,
    revisions,
  } = data;
  return (
    <Container className={STYLES?.ACCORDION_DATA_BODY}>
      <AccordianDataCell title={"Page type"} description={page_type} />
      <AccordianDataCell
        title={CONSTANTS?.PRICE}
        description={`$${price}.00`}
      />
      <AccordianDataCell title={"Business Name"} description={business_name} />
      <AccordianDataCell title={"Website URL"} description={website_url} />
      <AccordianDataCell
        title={"If rewrite, provide link to existing page"}
        description={existing_url}
      />
      <AccordianDataCell
        title={"Content requirements"}
        description={content_requirements}
      />
      <AccordianDataCell
        title={"Content examples"}
        description={content_examples}
      />
      <AccordianDataCell title={"Keywords"} description={keywords} />
      <AccordianDataCell
        title={"Additional notes"}
        description={additional_notes}
      />
      {revisions?.length > 0 ? (
        <>
          <Container className={STYLES?.ACCORDION_DATA_CONTAINER}>
            <Container className={STYLES?.ACCORDION_DATA_INNER_CONTAINER}>
              <p className={"upload-file-accordion-title"}>Uploaded File</p>

              {revisions.map((item, ind) => {
                return (
                  <Container className={STYLES?.UPLOADED_FILE_NAME_CONTAINER}>
                    <Image src={PaperClip} alt="uploaded" />
                    <a
                      href={item?.document_url}
                      className={STYLES?.UPLOAD_TEXT}
                      download
                    >
                      {item?.document_url}
                    </a>
                  </Container>
                );
              })}
            </Container>
          </Container>
        </>
      ) : null}
    </Container>
  );
};

export default AccordionData;
