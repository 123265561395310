import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";


export  const schema = yup.object().shape({
    Email: yup
    .string()
    .email(CONSTANTS?.CORRECT)
    .required(CONSTANTS?.EMAIL_VALUE)
    .max(50, CONSTANTS?.EMAIL_MAX)
    .min(4, CONSTANTS?.EMAIL_MIN),
    Password: yup
    .string()
    .required(CONSTANTS?.PASSWORD_VALUE)
    .min(6, CONSTANTS?.PASSWORD_MIN)
    .max(50, CONSTANTS?.PASSWORD_MAX),
});

export const defaultValues = {
  Email: "",
  Password: "",
};