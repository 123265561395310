import { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ROUTES } from "../Helpers/Constants";
import UserManagement from "../Components/Organisms/UserManagement";
import OrderManagement from "../Components/Organisms/UserOrderManagement";
import Billing from "../Components/Organisms/Billing/Billing";
import Setting from "../Components/Organisms/Setting";
import UserLogin from "../Components/Organisms/UserLogin";
import AdminRoutes from "../Helpers/AdminRoutes";
import ClientRoutes from "../Helpers/ClientRoutes";
import Order from "../Components/Organisms/Order/Order";
import OrderDetails from "../Components/Molecules/OrderDetails/index";
import UserBilling from "../Components/Organisms/UserBilling/UserBilling";
import UserSetting from "../Components/Organisms/UserSetting/UserSetting";
import AppContext from "../Context/AppContext";
import PrivateRoute from "../Helpers/PrivateRoute";
import CreateOrder from "../Components/Organisms/CreateOrder/CreateOrder";
import AdminLogin from "../Components/Organisms/AdminLogin";
import AdminOrderDetails from "../Components/Organisms/AdminOrderDetails/AdminOrderDetails";
import UserForgotPasswrord from "../Components/Organisms/UserForgotPassword/UserForgotPassword";
import UserResetPassword from "../Components/Organisms/UserResetPassword/UserResetPassword";
import SignUp from "../Components/Organisms/SignUp";
import LandingPage from "../Components/Organisms/LandingPage/LandingPage";

function AppRoutes() {
  const { isAuthenticated } = useContext(AppContext);

  return (
    <Routes>
      {/* <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to={ROUTES?.USER_MANAGEMENT} />
          ) : (
            <Navigate to={ROUTES?.USER_LOGIN} />
          )
        }
      /> */}
      <Route
        path="/"                                                                                
        element={
          isAuthenticated ? (
            <Navigate to={ROUTES?.USER_MANAGEMENT} />
          ) : (
            <Navigate to={ROUTES?.LANDING_PAGE} />
          )
        }
      />
      <Route
        path={ROUTES?.USER_LOGIN}
        element={<PrivateRoute element={<UserLogin />} />}
      />
      <Route
        path={ROUTES?.USER_SIGN_UP}
        element={<PrivateRoute element={<SignUp />} />}
      />

      <Route
        path={ROUTES?.USER_FORGOT_PASSWORD}
        element={<PrivateRoute element={<UserForgotPasswrord />} />}
      />
      <Route path={ROUTES?.LANDING_PAGE} element={<LandingPage />} />
      <Route
        path={ROUTES?.ADMIN_LOGIN}
        element={<PrivateRoute element={<AdminLogin />} />}
      />

      <Route element={<AdminRoutes />}>
        <Route path={ROUTES?.USER_MANAGEMENT} element={<UserManagement />} />
        <Route path={ROUTES?.ORDER_MANAGEMENT} element={<OrderManagement />} />
        <Route
          path={`${ROUTES?.ORDER_MANAGEMENT}${ROUTES?.ORDER_DETAILS}/:id`}
          element={<AdminOrderDetails />}
        />
        <Route path={ROUTES?.BILLING} element={<Billing />} />
        <Route path={ROUTES?.SETTING} element={<Setting />} />
      </Route>

      <Route
        path={ROUTES?.CREATE_NEW_PASSWORD}
        element={<UserResetPassword />}
      />
      <Route element={<ClientRoutes />}>
        <Route path={ROUTES?.ORDER} element={<Order />} />
        <Route
          path={`${ROUTES?.ORDER}${ROUTES?.CREATE_NEW_ORDER}/:id`}
          element={<OrderDetails />}
        />

        <Route path={ROUTES?.USER_BILLING} element={<UserBilling />} />
        <Route path={ROUTES?.USER_SETTING} element={<UserSetting />} />
        <Route
          path={`${ROUTES?.ORDER}${ROUTES?.CREATE_NEW_ORDER_FORM}`}
          element={<CreateOrder />}
        />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
