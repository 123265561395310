export const EditButton = ({ row, setRowData, setEditPopUp }) => {
  const handleEdit = () => {
    setRowData(row);
    setEditPopUp(true);
  };

  return <div onClick={handleEdit}>Edit</div>;
};

export const DeleteButton = ({ row, setRowID, setDeletePopUp }) => {
  const handleDelete = () => {
    setRowID(row?.id);
    setDeletePopUp(true);
  };

  return <div onClick={handleDelete}>Delete</div>;
};

export const AddInvoice = ({ row, setRowID, setIsAddInvoice }) => {
  const addInvoice = () => {
    setRowID(row?.id);
    setIsAddInvoice(true);
  };
  const isClickable = row?.payment_method !== "stripe";

  return (
    <div
      onClick={isClickable ? addInvoice : null}
      style={{ cursor: isClickable ? "pointer" : "not-allowed" }}
    >
      Add Invoice
    </div>
  );
  // return <div onClick={addInvoice}>Add Invoice</div>;
};
