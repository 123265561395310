import React from "react";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/Constants";

import CardHeader from "../CardHeader";
import "./Card.scss";
import { Text } from "../Typography/Text";

const Card = ({ CardTitle, image, CardCount, className }) => {
  return (
    <Container className={STYLES?.USER_ORDER_CARD}>
      <CardHeader CardTitle={CardTitle} image={image} />
      <Container className={className}  >
        <Text>{CardCount}</Text>
      </Container>
    </Container>
  );
};

export default Card;
