import React from "react";
import Container from "../Atoms/Container/Container";
import { STYLES } from "../../Helpers/Constants";
import "./HamburgerLandingPage.scss";
import Hamburger from "hamburger-react";

const HamburgerLandingPage = ({ isOpen, setOpen }) => {
  return (
    <Container className={STYLES?.HAMBURGER_MAIN_CONTAINER}>
      <Hamburger
        direction="left"
        color="#556C85"
        onToggle={(toggled) => setOpen(toggled)}
      />
    </Container>
  );
};

export default HamburgerLandingPage;
