import React, { useState, useRef, useEffect, useContext } from "react";
import "./UploadFile.scss";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import UploadIcon from "../../../Assets/upload.svg";
import PaperClip from "../../../Assets/PaperClip.svg";
import DeleteIcon from "../../../Assets/DeleteFade.svg";
import EyeIcon from "../../../Assets/Eye.svg";
import NormalBlackText from "../../Atoms/NormalBlackText";
import Image from "../../Atoms/Image";
import { Text } from "../../Atoms/Typography/Text";
import { uploadDocument } from "../../../Helpers/Functions";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
import AppContext from "../../../Context/AppContext";
import { errorToast } from "../../../Helpers/useToast";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
const UploadFile = ({ fileUrl, setFileUrl, previous }) => {
  const { isLoading, setIsLoading } = useContext(AppContext);
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = async (event) => {
    setIsLoading(true);
    const uploadedFile = event.target.files[0];
    if (uploadedFile.size > 10 * 1024 * 1024) {
      errorToast("File size exceeds 10MB limit.");
      console.log("Size", uploadedFile.size);
      event.target.value = null;
      return;
    }
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    const res = await uploadDocument(formData, setFileUrl);
    if (res) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedFile(fileUrl);
  }, [fileUrl]);

  const HandleClick = () => {
    setSelectedFile(false);
  };

  const handleTextClick = () => {
    fileInputRef.current.click();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDropChange = async (event) => {
    const formData = new FormData();
    formData.append("file", event.dataTransfer.files[0]);
    const res = await uploadDocument(formData, setFileUrl);
    if (res) {
      setIsLoading(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsDragging(false);
    const droppedFiles = event.dataTransfer.files;

    const allowedExtensions = [".xls", ".doc", ".csv"];

    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setIsLoading(false);

        errorToast("Only .xls, .doc, and .csv files are allowed.");
        return;
      }
    }
    handleDropChange(event);
  };

  return (
    <>
      {!isLoading ? (
        <Container className={STYLES?.UPLOAD_FILE_MAIN_CONTAINER}>
          {selectedFile ? (
            <Container className={STYLES?.PREVIOUS_FILE_CONTAINER}>
              {previous?.length > 0 ? (
                <>
                  <NormalBlackText text={"New file"} />
                  <RowSpace margin={15} />
                </>
              ) : (
                <Text className={STYLES?.UPLOAD_HEAD_TEXT}>
                  {CONSTANTS?.UPLOAD_DOCUMENT}
                </Text>
              )}

              <Container className={STYLES?.UPLOADED_FILE_INFO}>
                <Container className={STYLES?.UPLOADED_FILE_NAME_CONTAINER}>
                  <Image src={PaperClip} alt="uploaded" />
                  <a
                    target="_Blank"
                    href={selectedFile?.name}
                    className={STYLES?.UPLOAD_TEXT}
                    rel="noopener noreferrer"
                  >
                    {selectedFile}
                  </a>
                </Container>
                <img
                  src={DeleteIcon}
                  className={STYLES?.CURSOR_POINTER}
                  alt="Delete"
                  onClick={() => HandleClick()}
                />
              </Container>
            </Container>
          ) : (
            <div
              className={`${STYLES?.UPLOAD_FILE_CONTAINER} ${
                isDragging ? STYLES?.DRAG_OVER : ""
              }`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <NormalBlackText text={CONSTANTS?.UPLOAD_FILE} />
              <Container className={STYLES?.UPLOAD_FILE_INNER}>
                <Container className={STYLES?.UPLOAD_FILE_CONTENT}>
                  <Container className={STYLES?.UPLOAD_FILE_ICON}>
                    <Image src={UploadIcon} alt="upload" />
                  </Container>
                  <Container
                    className={`${STYLES?.DRAG_DROP} ${STYLES?.UPLOAD_FILE_BOX}`}
                  >
                    <div>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".xls, .doc, .csv"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <div
                        className={STYLES?.UPLOAD_TEXT}
                        onClick={handleTextClick}
                      >
                        {CONSTANTS?.UPLOAD_A_FILE}
                      </div>
                    </div>
                    <Text>{CONSTANTS?.DRAG_DROP_TEXT}</Text>
                  </Container>
                  <Container className={STYLES?.UPLOAD_FILE_TYPE}>
                    <Text>{CONSTANTS?.UPLOAD_FILE_TYPES}</Text>
                  </Container>
                </Container>
              </Container>
            </div>
          )}
          {previous?.length > 0 && (
            <>
              <Container className={STYLES?.PREVIOUS_FILE_CONTAINER}>
                <NormalBlackText text={"Previous file"} />
                <RowSpace margin={15} />
                {previous.map((item, ind) => {
                  return (
                    <Container
                      className={STYLES?.PREVIOUS_FILE_INNER_CONTAINER}
                    >
                      <Container
                        key={ind}
                        className={STYLES?.UPLOADED_FILE_NAME_CONTAINER}
                      >
                        <Image src={PaperClip} alt="uploaded" />
                        <a
                          target="_blank"
                          href={item?.document_url}
                          className={STYLES?.UPLOAD_TEXT}
                          rel="noopener noreferrer"
                        >
                          {item?.document_url}
                        </a>
                      </Container>
                      <img
                        src={EyeIcon}
                        className={STYLES?.CURSOR_POINTER}
                        alt="Delete"
                        onClick={() => HandleClick()}
                      />
                    </Container>
                  );
                })}
              </Container>
            </>
          )}
        </Container>
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default UploadFile;
