/** @format */
import React from "react";
import "./DeleteConfirmation.scss";
import Image from "../../Atoms/Image/Image";
import WarningIcon from "../../../Assets/Warning.svg";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import { Text } from "../../Atoms/Typography/Text";
import ButtonSecondary from "../../Atoms/ButtonSecondary/ButtonSecondary";

const DeleteConfirmation = ({
  ConfirmDelete,
  HandleCancel,
  type = "order",
}) => {
  return (
    <Container className={STYLES?.DELETE_MESSAGE_CONTAINER}>
      <Container className={STYLES?.DELETE_MESSAGE_BOX}>
        <Container className={STYLES?.DELETE_MESSAGE_HEADER}>
          <Image
            src={WarningIcon}
            alt="Logo"
            className={STYLES?.DELETE_MESSAGE_LOGO}
          />
          <Container className={STYLES?.DELETE_MESSAGE_HEADER_TEXT}>
            <Container className={STYLES?.DELETE_MESSAGE_TEXT}>
              <Text>{CONSTANTS?.DELETE}</Text>
            </Container>
            <Container className={STYLES?.DELETE_CONFIRMATION_TEXT}>
              <Text>{type === "user" ? CONSTANTS?.USER_DELETE_CONFIRMATION_TEXT : CONSTANTS?.ORDER_DELETE_CONFIRMATION_TEXT}</Text>
            </Container>
          </Container>
        </Container>
        <Container className={STYLES?.DELETE_MESSAGE_FOOTER}>
          <ButtonSecondary
            buttonText={CONSTANTS?.DELETE}
            className={STYLES?.DELETE_BUTTON}
            onClick={ConfirmDelete}
          />
          <ButtonSecondary
            buttonText={CONSTANTS?.CANCEL}
            className={STYLES?.DELETE_CANCEL_BUTTON}
            onClick={HandleCancel}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default DeleteConfirmation;
