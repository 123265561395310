/** @format */

import React from "react";
import { Text } from "../Typography/Text";

const Button = ({ type, className, buttonText, onClick }) => {
  return (
    <button type={type} className={className} onClick={onClick}>
      <Text>{buttonText}</Text>
    </button>
  );
};

export default Button;
