let fire_config;
let api_url;
let app_redirect_url;
let stripeKey;

if (process.env.REACT_APP_STATE === "wordwell-staging") {
  fire_config = "AIzaSyC3nK2QmyzhocdTkIWN0zayzZty5QJpoYU";
  api_url = "https://stgbackend.wordwellpro.com";
  app_redirect_url = "https://devapp.wordwellpro.com";
  stripeKey = process.env.REACT_APP_STRIPE_KEY;
} else if (process.env.REACT_APP_STATE === "wordwell-production") {
  fire_config = "AIzaSyBd4NGP2VXIyogzQh3zyAekCepZqOTOC7w";
  api_url = "https://prodbackend.wordwellpro.com";
  app_redirect_url = "https://devapp.wordwellpro.com";
  stripeKey = process.env.REACT_APP_STRIPE_KEY;
}

export const firebaseConfig = fire_config;
export const REACT_APP_API_URL = api_url;
export const REACT_APP_URL = app_redirect_url;
export const REACT_APP_FIREBASE_KEY = firebaseConfig;
export const REACT_APP_STRIPE_KEY = stripeKey;
