import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import { Text } from "../../Atoms/Typography/Text";
import "./BottomContent.scss";

const BottomContent = () => {
  return (
    <Container className={STYLES?.FOOTER_LINK_CONTAINER}>
      <Container className={STYLES?.BOTTOM_LINKS}></Container>

      <Container className={STYLES?.SMALL_BLACK_TEXT_SIMPLE}>
        <Text>{CONSTANTS?.ALL_RIGHTS_TEXT}</Text>
      </Container>
    </Container>
  );
};

export default BottomContent;
