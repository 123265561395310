import React from "react";
import PropTypes from "prop-types";
import "./Avatar.scss"; // Import the CSS file

const Avatar = ({ imageUrl, name }) => {
  // const getRandomColor = () => {
  //   const letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  const getInitials = () => {
    const nameArray = name?.split(" ");
    return nameArray
      ?.map((word) => word[0])
      ?.join("")
      ?.toUpperCase();
  };

  return (
    <div
      className="circular-avatar"
      style={{
        backgroundColor: imageUrl ? "transparent" : "#4D5562",
      }}
    >
      {imageUrl ? (
        <img src={imageUrl} alt="Avatar" />
      ) : (
        <span>{getInitials()}</span>
      )}
    </div>
  );
};

Avatar.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default Avatar;
