import React from "react";
import Container from "../Container/Container";
import "./CardHeader.scss";
import { STYLES } from "../../../Helpers/Constants";
import { Text } from "../Typography/Text";

const CardHeader = ({ CardTitle, image }) => {
  return (
    <Container className={STYLES?.CARD_HEADER}>
      <Container className={STYLES?.CARD_HEADER_TEXT}>
        <Text>{CardTitle}</Text>
      </Container>
      <Container>{image && <img src={image} alt="Icon" />}</Container>
      <Container></Container>
    </Container>
  );
};

export default CardHeader;
