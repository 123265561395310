import React from "react";
import { Text } from "../../Atoms/Typography/Text";
import Image from "../../Atoms/Image/Image";
import TagIcon from "../../../Assets/Tag.svg";
import "./CreateNewUser.scss";
import { STYLES } from "../../../Helpers/Constants";
import Container from "../../Atoms/Container/Container";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
const CreateNewUser = ({
  setShowPopup,
  buttonText = "",
  text,
  text1,
  onClicked,
}) => {
  return (
    <Container>
      <Container className={STYLES?.CREATE_NEW_USER_CONTAINER}>
        <Image src={TagIcon} alt="Logo" className={STYLES?.NEW_USER_TAG_ICON} />
        <Text className={STYLES?.NOT_FOUND_TITLE}>{text1} </Text>
        <Text className={`${STYLES?.NO_USERS} text-center`}variant="p">
        {text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br/>
    </React.Fragment>
  ))}
        </Text>
        <RowSpace margin={16} />
        {buttonText !== "" && (
          <PrimaryButton buttonText={buttonText} onClick={onClicked} />
        )}
      </Container>
    </Container>
  );
};
export default CreateNewUser;
