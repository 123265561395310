import React, { useContext, useEffect, useState } from "react";
import Container from "../../Atoms/Container/Container";
import { Text } from "../../Atoms/Typography/Text";
import "./SettingPassword.scss";
import InputField from "../../Atoms/InputField/InputField";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AppContext from "../../../Context/AppContext";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
import { defaultValues, schema } from "./schema";
import InputFieldPassword from "../../Atoms/InputFieldPassword";
import { ResetPasswordEmail, updatePassword } from "../../../Helpers/Functions";
import { errorToast, successToast } from "../../../Helpers/useToast";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";

const SettingPassword = () => {
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [isGeneratingOTP, setIsGeneratingOTP] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const { isLoading, setIsLoading, userProfile } = useContext(AppContext);
  setIsLoading(false);

  useEffect(() => {
    let interval;
    if (isGeneratingOTP) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(interval);
            setIsGeneratingOTP(false);
            return 30; // Reset countdown to 30 when it reaches 0
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isGeneratingOTP]);

  const generateOtp = async () => {
    setIsGeneratingOTP(true);
    const resData = await ResetPasswordEmail(userProfile?.email);
    if (resData.status !== 200) {
      errorToast("Something went wrong");
      setIsGeneratingOTP(false);
      setCountdown(30);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { Password, OTP } = data;
    setIsLoading(true);

    const res = await updatePassword(Password, OTP, userProfile.email);
    if (res.status === 200) {
      setIsLoading(false);
      successToast("Password updated successfully");
      localStorage.clear();
      window.location.href = "/";
    } else {
      setIsLoading(false);
      errorToast(res.message);
    }
  };

  const formClass = ErrorMessage
    ? STYLES?.USER_MANAGEMENT_LOGIN_FORM_ERROR
    : STYLES?.User_Login_Form;
  const inputFieldClass = ErrorMessage
    ? STYLES?.INPUT_FORM_FIELD_ERROR_USER_MANAGEMENT
    : STYLES?.USER_MANAGEMENT_INPUT_FIELD;

  return (
    <Container>
      <Container className="setting-password-title">
        <Text>Update Password</Text>
      </Container>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
            <Container className="setting-password-fields">
              <InputField
                name={CONSTANTS?.OTP}
                type="text"
                label={CONSTANTS?.OTP}
                register={register}
                field="OTP"
                errors={errors}
                setErrorMessage={setErrorMessage}
                className={inputFieldClass}
              />
              <InputFieldPassword
                name={CONSTANTS?.PASSWORD}
                register={register}
                field="Password"
                errors={errors}
                label={CONSTANTS?.NEW_PASSWORD}
                className={inputFieldClass}
                // setIsError={setIsError}
                type="Password"
                onChange={() => {}}
              />
              <InputFieldPassword
                name="confirmPassword"
                register={register}
                field="confirmPassword"
                errors={errors}
                label={CONSTANTS?.CONFIRM_PASSWORD}
                className={inputFieldClass}
                // setIsError={setIsError}
                type="Password"
                onChange={() => {}}
              />
            </Container>
            <Container className="setting-password-buttons">
              <PrimaryButton buttonText={"Update Password"} />
              <PrimaryButton
                buttonText={
                  isGeneratingOTP
                    ? `Generate OTP (${countdown})`
                    : "Generate OTP"
                }
                onClick={generateOtp}
                isCancel
                disabled={isGeneratingOTP}
              />
            </Container>
          </form>
        </>
      )}
    </Container>
  );
};

export default SettingPassword;
