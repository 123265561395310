/** @format */
import React, { useState } from "react";

// import "./CreateNewPassword.scss";
import Container from "../../Atoms/Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import UserLoginBackground from "../../Atoms/UserLoginBackground";
import CreateNewPassword from "../../Molecules/CreateNewPassword/CreateNewPassword";
import OtpVerification from "../../Molecules/OtpVerfication/OtpVerfication";

const UserResetPassword = () => {
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(1);

  //   const formClass = isError
  //     ? STYLES.LOGIN_CREATE_PASSWORD_ERROR
  //     : STYLES.CREATE_LOGIN_PASSWORD;
  //   const inputFieldClass = isError
  //     ? STYLES.INPUT_FIELD_ERROR
  //     : STYLES.INPUT_FORM;
  return (
    <Container className={STYLES?.CREATE_NEW_PASSWORD_USER}>
      {step === 1 && (
        <CreateNewPassword
          step={step}
          setStep={setStep}
          setPassword={setPassword}
        />
      )}
      {step === 2 && <OtpVerification password={password} />}
      <UserLoginBackground />
    </Container>
  );
};
export default UserResetPassword;
