import React from "react";
import { Text } from "../Typography/Text";

const HyperLink = ({ text, className, href, number }) => {
  return (
    <a href={href} className={className}>
      <Text>
        <span>{number}</span>
        {text}
      </Text>
    </a>
  );
};

export default HyperLink;
