export const Orderoptions = [
  { value: "neworder", label: "New Order" },
  { value: "inprogress", label: "In Progress" },
  { value: "revise", label: "Asked for Revision" },
  { value: "received", label: "Received" },
  { value: "completed", label: "Completed" },
];

export const UserManagementoptions = [
  { value: "invited", label: "Invited" },
  { value: "accepted", label: "Accepted" },
];
export const PaymentMethods = [
  { value: "quickbooks", label: "Quickbooks" },
  { value: "stripe", label: "Stripe" },
];
export const PaymentStatus = [
  { value: "paid", label: "Paid" },
  { value: "unpaid", label: "UnPaid" },
];
export const CreateOrderOptions = [
  { value: "60", label: "Blog Post (400-600 words)" },
  { value: "120", label: "Blog Post (800-1000 words)" },
  { value: "300", label: "Blog Post (2000 words)" },
  { value: "90", label: "Website Page (600-800 words)" },
  { value: "390", label: "Website Pack (5 website pages)" },
];
export const customStylesCreateOrder = (errors) => ({
  menu: (provided) => ({
    ...provided,
    padding: 5,
    margin: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    appearance: "none",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    border: errors?.["SelectOption"]
      ? "3px solid #C52A1A"
      : state.isFocused
      ? "3px solid #059669"
      : "3px solid #ced4da",
    ":hover": {
      border: errors?.["SelectOption"]
        ? "3px solid #C52A1A"
        : state.isFocused
        ? "3px solid #059669"
        : "3px solid #ced4da",
    },
    boxShadow: "none",
    borderRadius: "5px",
  }),
  option: (provided, state) => ({
    ...provided,

    backgroundColor: state.isSelected
      ? "#05966F"
      : state.isFocused
      ? "#05966F"
      : "",
    color: state.isSelected ? "white" : state.isFocused ? "white" : "black",
    boxShadow: "none",
  }),
  // selectContainer: (provided, state) => ({
  //   ...provided,

  //   backgroundColor: state.isSelected ? "#05966F" : "red",
  //   color: state.isSelected ? "white" : state.isFocused ? "white" : "black",
  // }),
  menuPortal : (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "red" 
      : state.isFocused
      ? "yellow" 
      : "",
    color: state.isSelected ? "white" : state.isFocused ? "white" : "black",
  }),
});
