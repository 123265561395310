import React from "react";
import PreButton from "../../../Assets/ChevronLeft.svg";
import "./CustomPagination.scss";

const CustomPagination = ({
  limit,
  noRows = 6,
  count,
  setCurrentPage,
  currentPage,
  onChangePage,
}) => {
  const totalPages = Math.ceil(count / limit);

  const handlePrev = () => {
    if (currentPage > 1) {
      onChangePage(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      onChangePage(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div className="custom-pagination">
      <div className="pagination-left">
        <span>{`Showing ${noRows} to ${limit} of ${currentPage} results`}</span>{" "}
      </div>
      <div className="pagination-right">
        <button
          className="pagination_button"
          onClick={() => handlePrev()}
          disabled={currentPage === 1}
        >
          <img src={PreButton} alt="Previous" />
        </button>
        <button className="centerpage">{currentPage}</button>
        <button
          className="pagination_button-next"
          onClick={() => handleNext()}
          disabled={currentPage === totalPages}
        >
          <img src={PreButton} alt="Next" className="rotate-icon" />
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;
