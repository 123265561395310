import React from "react";
import Container from "../../Atoms/Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import "./AdminLogin.scss";
import SignIn from "../SignIn";
const AdminLogin = () => {
  return (
    <Container className={STYLES?.ADMIN_LOGIN_PAGE}>
      <SignIn />
    </Container>
  );
};

export default AdminLogin;
