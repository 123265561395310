import React, { useState, useEffect, useRef } from "react";
import "./DotsMenu.scss";
import { STYLES } from "../../../Helpers/Constants";

const DotsMenu = ({ options }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const dropDownContent =
    options?.length > 1
      ? STYLES?.DROP_DOWN_CONTENT_EXTENDED
      : STYLES?.DROP_DOWN_CONTENT_SINGLE;
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="dots-menu-container" ref={dropdownRef}>
      <div className="dots-menu-icon" onClick={handleDropdownToggle}>
        &#8942;
      </div>
      {isDropdownOpen && <div className={dropDownContent}>{options}</div>}
    </div>
  );
};

export default DotsMenu;
