import React from "react";
import "./HamburgerMenu.scss";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/Constants";

const HamburgerMenu = ({ onToggleMenu }) => {
  return (
    <Container className={STYLES?.HAMBURGER_CONTAINER}>
      <button class={STYLES?.TOGGLE_BUTTON} onClick={onToggleMenu}>
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
    </Container>
  );
};

export default HamburgerMenu;
