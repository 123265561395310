import React, { useState, useEffect, useRef } from "react";
import { updateStatus } from "../../../Helpers/Functions";
import DownArrowIcon from "../../../Assets/DownArrow_brown.svg";
import { OrderStatuses } from "../../../Helpers/Constants";
import "./StatusTag.scss";
const StatusTag = ({ data, status, isAdmin = false }) => {
  const [selectedOption, setSelectedOption] = useState("neworder");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  let statusClass;
  const handleDropdownChange = (value) => {
    setSelectedOption(value);
    updateStatus(data?.id, value);
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  switch (status) {
    case "unpaid":
    case "invited":
    case "neworder":
    case "inprogress":
      statusClass = "status-unpaid";

      break;
    case "paid":
    case "accepted":
    case "signedup":
    case "completed":
      statusClass = "status-paid";

      break;
    case "revise":
      statusClass = "status-revise";

      break;
    default:
      statusClass = "status-default";
  }

  const dropdownStyle = {
    position: "absolute",
    top: "calc(100% + 5px)",
    left: 0,
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    zIndex: 9999,
  };

  useEffect(() => {
    setSelectedOption(status);
  }, [status]);

  return (
    <>
      {selectedOption === "neworder" && status === "neworder" ? (
        <div style={{ position: "relative" }} ref={dropdownRef}>
          <div className={statusClass} onClick={handleToggle}>
            {isAdmin
              ? OrderStatuses[selectedOption]?.admintext
              : OrderStatuses[selectedOption]?.text}
            {isAdmin ? (
              <div style={{ marginLeft: "auto", cursor: "pointer" }}>
                <img src={DownArrowIcon} alt="Down Arrow" />
              </div>
            ) : null}
          </div>
          {isAdmin && isOpen && (
            <div style={dropdownStyle}>
              <div
                onClick={() => handleDropdownChange("neworder")}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                New Order
              </div>
              <div
                onClick={() => handleDropdownChange("inprogress")}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                In Progress
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={statusClass}>
          {isAdmin
            ? OrderStatuses[selectedOption]?.admintext
            : OrderStatuses[selectedOption]?.text}
        </div>
      )}
    </>
  );
};

export default StatusTag;
