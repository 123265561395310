import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import logo from "../../../Assets/LandingPageAssets/logo-word-well.png";
import "./HeroBanner.scss";
import LandingPageButton from "../../Atoms/LandingPageButton/LandingPageButton";
import { useNavigate } from "react-router-dom";
import HamburgerLandingPage from "../../HamburgerLandingPage/HamburgerLandingPage";

const HeroBanner = ({ isOpen, setOpen }) => {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate(ROUTES?.USER_LOGIN);
  };
  return (
    <Container className={STYLES?.HERO_CONTAINER}>
      <HamburgerLandingPage isOpen={isOpen} setOpen={setOpen} />
      <img src={logo} className={STYLES?.HEADER_LOGO} alt="logo" />
      <LandingPageButton
        className={STYLES?.HEADER_LOGIN_BUTTON}
        buttonText={CONSTANTS?.LOG_IN}
        onClick={handleLoginClick}
      />
    </Container>
  );
};

export default HeroBanner;
