import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "../../../Assets/logo.svg";
import "./ForgotPassword.scss";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import Container from "../../Atoms/Container/Container";
import Image from "../../Atoms/Image/Image";
import InputField from "../../Atoms/InputField/InputField";
import { Text } from "../../Atoms/Typography/Text";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import { errorToast} from "../../../Helpers/useToast";
const ForgotPasswrord = ({ ResetPassword }) => {
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const defaultValues = {
    Email: "",
  };
  const schema = yup.object().shape({
    Email: yup
      .string()
      .email(CONSTANTS.CORRECT_EMAIL)
      .required(CONSTANTS.EMAIL_VALUE)
      .max(100, CONSTANTS.EMAIL_MAX)
      .min(4, CONSTANTS.EMAIL_MIN),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const formClass = isError ? STYLES?.LOGIN_FORGET_ERROR : STYLES?.LOGIN_FORGET;
  // const InputFieldClass = isError
  //   ? STYLES.INPUT_FIELD_FORGET_ERROR
  //   : STYLES.INPUT_FILED_FORGET;
  const InputFieldClass = isError
    ? STYLES?.INPUT_FIELD_ERROR
    : STYLES?.LOGIN_INPUT_FIELD;

  const onSubmit = async (data) => {
    const { Email: email } = data;
    localStorage.setItem("email_user", email);
    try {
      const resData = await ResetPassword(email);
      if (resData.status === 200) {
        navigate(ROUTES?.CREATE_NEW_PASSWORD);
      }
    } catch (error) {
      errorToast("Something went wrong");
      console.error(error);
      if (error.message.includes("Authentication failed")) {
        setErrorMessage("Enter Correct Credentials");
      }
    }
  };
  return (
    // <Container className={STYLES?.FORGORT_PASSWORD_USER}>
    <Container className={STYLES?.LOGIN_FORGET_CONTAINER}>
      <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
        <Container className={STYLES?.FORGOT_PASSWORD_HEADER}>
          <Image src={logo} alt="Logo" className={STYLES.LOGO_FORGET} />
          <h2>{CONSTANTS?.FORGET_PASSWORD_TITLE}</h2>
          <Text className={STYLES.RESET_FORGET_PASSWORD}>
            {CONSTANTS.FORGET_PASSWORD_TEXT}
          </Text>
        </Container>

        <InputField
          name={CONSTANTS?.EMAIL}
          field={CONSTANTS?.EMAIL}
          type={CONSTANTS?.EMAIL_TYPE}
          placeholder={CONSTANTS?.EMAIL_PLACEHOLDER}
          maxLength={50}
          register={register}
          errors={errors}
          setIsError={setIsError}
          label={CONSTANTS?.EMAIL_ADDRESS}
          className={InputFieldClass}
        />
        <PrimaryButton
          type={CONSTANTS?.BUTTON_TYPE}
          buttonText={CONSTANTS?.SEND}
        />
        {ErrorMessage && (
          <p style={{ margin: "0px", color: "red" }}>{ErrorMessage}</p>
        )}
      </form>
      <Container className={STYLES?.All_Rights_Reserved}>
        <Text>{CONSTANTS?.ALL_RIGHTS_TEXT}</Text>
      </Container>
    </Container>
    //  <UserLoginBackground />
    // </Container>
  );
};

export default ForgotPasswrord;
