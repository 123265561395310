import React from "react";
import Container from "../../Atoms/Container/Container";
import LandingPageButton from "../../Atoms/LandingPageButton/LandingPageButton";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import "./HeaderButton.scss";
import { useNavigate } from "react-router-dom";

const HeaderButton = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(ROUTES?.USER_LOGIN);
  };

  const handleSignUpClick = () => {
    navigate(ROUTES?.USER_SIGN_UP);
  };

  return (
    <Container className={STYLES?.HEADER_BUTTON_CONTAINER}>
      <LandingPageButton
        className={STYLES?.HEADER_LOGIN_BUTTON}
        buttonText={CONSTANTS?.LOG_IN}
        onClick={handleLoginClick}
      />
      <LandingPageButton
        className={STYLES?.HEADER_SIGNUP_BUTTON}
        buttonText={CONSTANTS?.SIGN_UP}
        onClick={handleSignUpClick}
      />
    </Container>
  );
};

export default HeaderButton;
