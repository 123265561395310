import React from "react";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import "./Border.scss";

const Border = () => {
  return <Container className={STYLES?.BORDER}></Container>;
};

export default Border;
