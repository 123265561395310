import { toast } from "react-toastify";

export const successToast = (text) =>
  toast.success(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    rtl: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    // theme: "light",
  });

export const errorToast = (text) =>
  toast.error(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    rtl: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    // theme: "light",
  });
