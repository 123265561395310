import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DealsTableTitleRow from "../../Atoms/DealsTableTitleRow";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import CreateNewUser from "../../Molecules/CreateNewUser/CreateNewUser";
import CustomPagination from "../../Molecules/CustomPagination/CustomPagination";
import Container from "../../Atoms/Container/Container";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import { BillingColumn, customStyles } from "./BillingColumn";
import BoldBlackHeading from "../../Atoms/BoldBlackHeading/BoldBlackHeading";
import { PaymentMethods, PaymentStatus } from "../../../Helpers/FilterOptions";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
import SearchResult from "../../Molecules/SearchResult";
import CreateInvoiceForm from "../CreateInvoiceForm";
import { billingData, formatFilter } from "../../../Helpers/Functions";

const headingArr = [
  CONSTANTS?.ClIENT_NAME,
  CONSTANTS?.BILLING_DATE,
  CONSTANTS?.ORDER_COLUMN,
  CONSTANTS?.PAYMENT_METHOD,
  CONSTANTS?.STATUS,
  CONSTANTS?.ACTION,
];
const Billing = () => {
  let limit = 6;
  const [showPopup, setShowPopup] = useState(false);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [billing, setBilling] = useState([]);
  const [sort, setSort] = useState("");
  const [sortedData, setSortedData] = useState([]);
  const [ShowDropdown, setShowDropdown] = useState(false);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  useEffect(() => {
    const formattedFilter = formatFilter(filter);

    billingData({
      limit,
      currentPage,
      search,
      filter: formattedFilter,
      setBilling,
      setLoading,
      setCount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter, sort, currentPage]);

  useEffect(() => {
    if (billing && billing.length > 0) {
      setSortedData([...billing]);
    }
  }, [billing]);

  const addInvoiceToBilling = (newInvoice) => {
    setBilling([newInvoice, ...billing]);
  };

  return (
    <Container className={STYLES?.USER_MANAGEMENT_SCREEN}>
      {!showPopup ? (
        <Container className={STYLES?.MainContent}>
          <BoldBlackHeading text={CONSTANTS?.INVOICES} />
          <Container className={STYLES?.SEARCH_CONTENT}>
            <SearchResult
              onSearch={handleSearch}
              setSearch={setSearch}
              setFilter={setFilter}
              filter={filter}
              setSort={setSort}
              sortingValue={true}
              count={count}
              visibility={ShowDropdown}
              setVisibility={setShowDropdown}
              showFilter
              options={{
                Status: PaymentStatus,
                "Payment Method": PaymentMethods,
              }}
            />
            <Container>
              {billing?.length > 0 && (
                <PrimaryButton
                  buttonText={CONSTANTS?.CREATE_NEW_INVOICE}
                  onClick={() => setShowPopup(true)}
                />
              )}
            </Container>
          </Container>
          <RowSpace margin={33} />
          {!loading ? (
            <>
              {billing?.length > 0 ? (
                <div className={STYLES?.CUSTOM_TABLE}>
                  <DataTable
                    customStyles={customStyles}
                    noHeader={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={"48dvh"}
                    columns={BillingColumn()}
                    data={sortedData}
                    pagination
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 30]}
                    paginationComponent={(props) => (
                      <CustomPagination
                        {...props}
                        currentPage={currentPage}
                        limit={limit}
                        count={count}
                        setCurrentPage={setCurrentPage}
                        onChangePage={handleChangePage}
                      />
                    )}
                  />
                </div>
              ) : (
                <>
                  <DealsTableTitleRow list={headingArr} />
                  {!loading && (
                    <CreateNewUser
                      buttonText={CONSTANTS?.CREATE_NEW_INVOICE}
                      text={CONSTANTS?.NO_INVOICES_YET}
                      text1={CONSTANTS?.NO_INVOICE}
                      onClicked={() => setShowPopup(true)}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </Container>
      ) : (
        <CreateInvoiceForm
          setShowPopup={setShowPopup}
          onAdded={addInvoiceToBilling}
          allClients
        />
      )}
    </Container>
  );
};

export default Billing;
