import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import "./LandingPageBottom.scss";
import { Text } from "../../Atoms/Typography/Text";
import RowSpace from "../../../Components/Atoms/RowSpace/RowSpace";
import smallBG from "../../../Assets/LandingPageAssets/footer-bg-sm.png";
import { useNavigate } from "react-router-dom";

const LandingPageBottom = () => {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate(ROUTES?.USER_SIGN_UP);
  };
  return (
    <div>
      <Container className={STYLES?.BOTTOM_BG_CONTAINER}>
        <Container className={STYLES?.BOTTOM_GRADIENT}>
          <Container className={STYLES?.BOTTOM_CONTENT_CONTAINER}>
            <Text className={STYLES?.GREEN_STYLED_MED_TEXT}>
              {CONSTANTS?.WORDWELL_SLOGAN}
            </Text>
            <RowSpace margin={12} />

            <h1 className={STYLES?.SMALL_WHITE_TEXT}>
              {CONSTANTS?.WORDWELL_MESSAGE}
            </h1>
            <RowSpace margin={12} />

            <h1 className={STYLES?.SMALL_WHITE_TEXT}>
              {CONSTANTS?.MISSION_STATEMENT}
            </h1>

            <RowSpace margin={12} />
            <Container>
              <button
                className={STYLES?.GET_STARTED_BUTTON}
                onClick={handleSignUpClick}
              >
                {CONSTANTS?.GET_STARTED}
              </button>
            </Container>
          </Container>
        </Container>
      </Container>
      <div>
        <img
          src={smallBG}
          className={STYLES?.BOTTOM_BG_CONTAINER_SM}
          alt="background"
        />
      </div>

      <Container className={STYLES?.BOTTOM_CONTENT_CONTAINER_SM}>
        <Text className={STYLES?.GREEN_STYLED_MED_TEXT}>
          {CONSTANTS?.WORDWELL_SLOGAN}
        </Text>
        <RowSpace margin={12} />

        <h1 className={STYLES?.SMALL_WHITE_TEXT}>
          {CONSTANTS?.WORDWELL_MESSAGE}
        </h1>
        <RowSpace margin={12} />

        <h1 className={STYLES?.SMALL_WHITE_TEXT}>
          {CONSTANTS?.MISSION_STATEMENT}
        </h1>

        <RowSpace margin={12} />
        <Container>
          <button
            className={STYLES?.GET_STARTED_BUTTON}
            onClick={handleSignUpClick}
          >
            {CONSTANTS?.GET_STARTED}
          </button>
        </Container>
        <RowSpace margin={80} />
      </Container>
    </div>
  );
};

export default LandingPageBottom;
