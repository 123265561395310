import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import LandingPageButton from "../../Atoms/LandingPageButton/LandingPageButton";
import "./MainMenuBar.scss";

const MainMenuBar = ({ handleSignUpClick }) => {
  return (
    <Container className={STYLES?.MAIN_MENU_CONTAINER}>
      <LandingPageButton
        className={STYLES?.HEADER_SIGNUP_BUTTON}
        buttonText={CONSTANTS?.SIGN_UP}
        onClick={handleSignUpClick}
      />
    </Container>
  );
};

export default MainMenuBar;
