import React, { useState } from "react";
import "./InputFieldPassword.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import RowSpace from "../RowSpace/RowSpace";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/Constants";
const InputFieldPassword = ({
  name,
  register = () => {},
  field,
  type = "",
  placeholder = "",
  value,
  label,
  onChange,
  disabled = false,
  showIcon = true,
  errors = "",
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const InputFieldClass = errors[name] ? STYLES?.INPUT_FIELD_ERROR : "";
  return (
    <Container>
      <label className={STYLES?.LABEL}>{label}</label>
      <RowSpace margin={10} />
      <Container className={InputFieldClass}>
        <Container className={STYLES?.Input_Field_Container}>
          <input
            type={showPassword ? "text" : type}
            {...register(field)}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...restProps}
          />
          {showIcon && (
            <FontAwesomeIcon
              icon={!showPassword ? faEyeSlash : faEye}
              aria-hidden="true"
              className={STYLES?.Eye_Icon}
              onClick={handleTogglePassword}
            />
          )}
        </Container>
      </Container>
      {errors[name]?.message && (
        <div>
          <h5 className={STYLES?.ERROR_MESSAGE}>{errors[name]?.message}</h5>
        </div>
      )}
      {/* {errors && <p style={{ color: "red" }}>{errors}</p>} */}
    </Container>
  );
};
export default InputFieldPassword;
