import React from "react";
import "./InputField.scss";
import RowSpace from "../../Atoms/RowSpace/RowSpace";

import { STYLES } from "../../../Helpers/Constants";
import Container from "../Container/Container";
const InputField = ({
  name,
  register = () => {},
  field,
  link,
  type = "",
  placeholder = "",
  value,
  label,
  onChange,
  disabled = false,
  showIcon = true,
  errors = "",
  maxLength,
  isRequired = false,
  ...restProps
}) => {
  const InputFieldClass = errors[name] ? STYLES?.INPUT_FIELD_ERROR : "";
  const labelClass = `${STYLES?.LABEL} ${isRequired ? "required" : ""}`;
  return (
    <Container>
      <label className={labelClass}>{label}</label>
      <RowSpace margin={10} />
      <Container className={InputFieldClass}>
        <Container className={STYLES?.Input_Field_Container}>
          <input
            type={type}
            {...register(field)}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            maxLength={maxLength}
            {...restProps}
          />
        </Container>
      </Container>
      {errors[name]?.message && (
        <div>
          <div className={STYLES?.ERROR_MESSAGE}>{errors[name]?.message}</div>
        </div>
      )}
    </Container>
  );
};
export default InputField;
