import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./CreateNewPassword.scss";
import logo from "../../../Assets/logo.svg";
import Container from "../../Atoms/Container/Container";
import Image from "../../Atoms/Image/Image";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import InputFieldPassword from "../../Atoms/InputFieldPassword";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import { defaultValues, schema } from "./FormSchema";
import { Text } from "../../Atoms/Typography/Text";
import AppContext from "../../../Context/AppContext";
import { errorToast } from "../../../Helpers/useToast";
import {
  clientSignIn,
  fetchUserProfile,
  updateProfile,
} from "../../../Helpers/Functions";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
import { useNavigate } from "react-router-dom";

const CreateNewPassword = ({ step, setStep, setPassword }) => {
  const {
    isLoading,
    setIsLoading,
    setIsAuthenticated,
    setUserType,
    setUserProfile,
  } = useContext(AppContext);
  const [isError, setIsError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const storeAccessToken = (idToken, refreshToken) => {
    localStorage.setItem("accessToken", idToken);
    localStorage.setItem("refresh_token", refreshToken);
  };

  const onSubmit = async (data) => {
    const { Password } = data;
    const firstLogin = JSON.parse(localStorage.getItem("firstLogin"));
    if (!firstLogin) {
      setStep(step + 1);
      setPassword(Password);
    } else {
      setIsLoading(true);

      const body = { password: Password, first_login: false };
      const resp = await updateProfile(body);

      if (resp.status === 200) {
        const email = localStorage.getItem("user_email");
        const password = Password;
        try {
          const responseData = await clientSignIn(email, password, true);
          localStorage.setItem("userType", "user");
          sessionStorage.setItem("userType", "user");
          storeAccessToken(responseData.idToken, responseData.refreshToken);

          fetchUserProfile()
            .then((res) => {
              console.log("result: ", res);
              setUserProfile(res);
              setIsAuthenticated(true);
              setUserType("user");
              navigate(ROUTES?.ORDER);
            })
            .catch((err) => {
              console.log("err: ", err);
            });
        } catch (error) {
          if (error.message.includes("Authentication failed")) {
            setErrorMessage(CONSTANTS?.ERROR_MESSAGE_API_FAIL);
            setIsAuthenticated(false);
            setUserType(null);
          }
        }
      } else {
        errorToast("Something went wrong");
      }
      setIsLoading(false);
    }
  };
  const formClass = isError
    ? STYLES.LOGIN_CREATE_PASSWORD_ERROR
    : STYLES.CREATE_LOGIN_PASSWORD;
  const inputFieldClass = isError
    ? STYLES.INPUT_FIELD_ERROR
    : STYLES.INPUT_FORM;
  return (
    <Container className={STYLES?.CREATE_NEWPASSWORD_CONTAINER}>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <Container className={STYLES?.NEW_PASSWORD_INNER_CONTAINER}>
            <Container className={STYLES?.FORGOT_PASSWORD_HEADER}>
              <Image src={logo} alt="Logo" className={STYLES.LOGO_FORGET} />
              <h2 className={STYLES?.CREATE_PASSWORD_TITLE}>
                {CONSTANTS?.CREATE_NEW_PASSWORD}
              </h2>
            </Container>
            <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
              <InputFieldPassword
                name={CONSTANTS?.PASSWORD}
                register={register}
                field="Password"
                errors={errors}
                label={CONSTANTS?.NEW_PASSWORD}
                className={inputFieldClass}
                setIsError={setIsError}
                type="Password"
                onChange={() => {}}
              />
              <InputFieldPassword
                name="confirmPassword"
                register={register}
                field="confirmPassword"
                errors={errors}
                label={CONSTANTS?.CONFIRM_PASSWORD}
                className={inputFieldClass}
                setIsError={setIsError}
                type="Password"
                onChange={() => {}}
              />
              <PrimaryButton
                type={CONSTANTS?.BUTTON_TYPE}
                buttonText={CONSTANTS?.NEXT}
              />
              {ErrorMessage && (
                <p className={STYLES?.ERROR_MESSAGE}>{ErrorMessage}</p>
              )}
            </form>
          </Container>
          <Container className={STYLES?.All_Rights_Reserved}>
            <Text>{CONSTANTS?.ALL_RIGHTS_TEXT}</Text>
          </Container>
        </>
      )}
    </Container>
  );
};
export default CreateNewPassword;
