import React, { useEffect, useRef, useState } from "react";
import ArrowUp from "../../../Assets/arrow_up.svg";
import ArrowDown from "../../../Assets/arrow_down.svg";
import "./Accordion.scss";
import Image from "../Image";
import StatusTag from "../StatusTag/StatusTag";
import Container from "../Container/Container";

const Accordion = ({
  title = "Title",
  content,
  isAdmin = false,
  innerElement,
  status = false,
  defaultOpen = true,
  statusText,
  statusData = {},
}) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);
  const [height, setHeight] = useState("0px");
  const toggleAccordion = () => {
    setActive(!active);
    setHeight(active ? "0px" : `${contentRef.current.scrollHeight + 10}px`);
  };
  useEffect(() => {
    if (defaultOpen) {
      setHeight(`${contentRef.current.scrollHeight + 10}px`);
      setActive(true);
    }
  }, [defaultOpen]);
  return (
    <div className="accordion__section">
      <div
        className={`accordion ${active ? "active" : ""}`}
        onClick={() => toggleAccordion()}
      >
        <p className="accordion__title">{content?.page_type || title}</p>
        <Container className="accordion_status_down_arrow">
          {status && (
            <StatusTag
              data={statusData}
              isAdmin={isAdmin}
              status={statusText}
            />
          )}
          <span className="arrow-icon-accordian">
            {active ? (
              <Image src={ArrowUp} alt={"-"} />
            ) : (
              <Image src={ArrowDown} alt={"+"} />
            )}
          </span>
        </Container>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        {innerElement}
      </div>
    </div>
  );
};
export default Accordion;
