import React from "react";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import { Text } from "../Typography/Text";
import "./NormalBlackText.scss";

const NormalBlackText = ({text}) => {
  return (
    <Container>
      <Text className={STYLES?.NORMAL_BLACK_TEXT}>
        {text}
      </Text>
      
    </Container>
  );
};

export default NormalBlackText;
