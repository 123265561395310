import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import InputField from "../../Atoms/InputField/InputField";
import { Text } from "../../Atoms/Typography/Text";
import Button from "../../Atoms/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "../../Atoms/Image";
import CrossIcon from "../../../Assets/WhiteCross.svg";
import CustomSelect from "../../Atoms/CustomSelect/CustomSelect";
import "./CreateInvoiceForm.scss";
import { defaultValues, schema, secondarySchema } from "./schema";
import { createInvoice } from "../../../Helpers/Functions";
import { ERROR_MESSAGES, GET_USERS } from "../../../Helpers/APIUrls";
import fetchWithHeaders from "../../../FetchIntercepter";
import "../../Molecules/UserMangementLogin/UserManagementLogin.scss";
import { successToast } from "../../../Helpers/useToast";

const CreateInvoiceForm = ({
  setShowPopup,
  onAdded,
  allClients = false,
  userID = null,
}) => {
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [options, setOptions] = useState([]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(allClients ? schema : secondarySchema),
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchWithHeaders(
          `${GET_USERS}?payment_method[]=quickbooks`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const result = await response.json();
        const updatedOpt = result?.data?.map((user) => ({
          value: user.id,
          label: user.name,
        }));

        setOptions(updatedOpt);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, []);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      padding: 5,
      margin: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      appearance: "none",
      "-moz-appearance": "none",
      "-webkit-appearance": "none",
      border: errors?.["SelectOption"]
        ? "3px solid #C52A1A"
        : state.isFocused
        ? "1px solid #D1D5DB"
        : "1px solid #ced4da",
      ":hover": {
        border: errors?.["SelectOption"]
          ? "3px solid #C52A1A"
          : state.isFocused
          ? "1px solid #D1D5DB"
          : "1px solid #ced4da",
      },

      borderRadius: "5px",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#05966F" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const onSubmit = async (data) => {
    const { InvoiceLink, Amount, User, Date } = data;

    try {
      const newInvoice = await createInvoice(
        InvoiceLink,
        Number(Amount),
        allClients ? User.value : userID,
        Date.toISOString().split("T")[0]
      );
      onAdded(newInvoice);
      successToast(CONSTANTS?.INVOICE_ADDED_TEXT);
      setShowPopup(false);
    } catch (error) {
      if (error.message.includes(ERROR_MESSAGES?.CONFLICT)) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(error?.message?.includes(ERROR_MESSAGES?.AUTH_FAILED));
      }
    }
  };

  const formClass = ErrorMessage
    ? STYLES?.USER_MANAGEMENT_LOGIN_FORM_ERROR
    : STYLES?.User_Login_Form;
  const inputFieldClass = ErrorMessage
    ? STYLES?.INPUT_FORM_FIELD_ERROR_USER_MANAGEMENT
    : STYLES?.USER_MANAGEMENT_INPUT_FIELD;

  return (
    <Container className={STYLES?.USER_MANAGEMENT_LOGIN_PAGE}>
      <Container className={STYLES?.USER_MANAGEMENT_POPUP}>
        <Container onClick={handleClosePopup} className={STYLES?.CROSS_ICON}>
          <Image src={CrossIcon} />
        </Container>
        <Container className={STYLES?.LOGIN_USER_MANAGEMENT}>
          <Container>
            <Text variant="h5" className={STYLES?.USER_MANAGEMENT_LOGIN_TEXT}>
              {CONSTANTS?.ADD_INVOICE}
            </Text>
            <Container>
              <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
                <InputField
                  name={CONSTANTS?.INVOICE_LINK}
                  type={CONSTANTS?.TEXT}
                  label={CONSTANTS?.INVOICE_LINK_LABEL}
                  register={register}
                  field={CONSTANTS?.INVOICE_LINK}
                  errors={errors}
                  setErrorMessage={setErrorMessage}
                  className={inputFieldClass}
                />
                <InputField
                  label={CONSTANTS?.AMOUNT}
                  name={CONSTANTS?.AMOUNT}
                  type={CONSTANTS?.TEXT}
                  field={CONSTANTS?.AMOUNT}
                  register={register}
                  setErrorMessage={setErrorMessage}
                  errors={errors}
                  className={inputFieldClass}
                />
                {allClients && (
                  <Controller
                    label={CONSTANTS?.SELECT_USER}
                    name={CONSTANTS?.USER}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <CustomSelect
                        label={CONSTANTS?.SELECT_USER}
                        options={options}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        SelectOption={value}
                        setErrorMessage={setErrorMessage}
                        errors={errors}
                        styles={customStyles}
                      />
                    )}
                  />
                )}

                <InputField
                  label={CONSTANTS?.DUE_DATE}
                  name={CONSTANTS?.DATE}
                  type={CONSTANTS?.DATE}
                  field={CONSTANTS?.DATE}
                  register={register}
                  setErrorMessage={setErrorMessage}
                  placeholder="New Date"
                  min={new Date().toISOString().split("T")[0]}
                  errors={errors}
                  className={inputFieldClass}
                  pattern="\d{4}-\d{2}-\d{2}"
                />

                <Container className={STYLES?.USER_MANAGEMENT_BUTTON}>
                  <Button
                    type="button"
                    className={STYLES?.CANCEL_BUTTON}
                    buttonText={CONSTANTS?.CANCEL}
                    onClick={handleClosePopup}
                  />
                  <Button
                    type={CONSTANTS?.BUTTON_TYPE}
                    className={STYLES?.SEND_BUTTON}
                    buttonText={CONSTANTS?.ADD}
                    // onClick={EditForm}
                  />
                </Container>
              </form>
            </Container>
          </Container>
          {ErrorMessage && (
            <p className={STYLES?.Login_Error_Message}>{ErrorMessage}</p>
          )}
        </Container>
      </Container>
    </Container>
  );
};
export default CreateInvoiceForm;
