import React, { useContext, useEffect, useState } from "react";
import { Text } from "../../Atoms/Typography/Text";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import "./BillingSetting.scss";
import PaymentSetting from "../PaymentSetting/PaymentSetting";
import SavedPayment from "../SavedPayment/SavedPayment";
import {
  allPaymentMethods,
  DeletePaymentMethod,
} from "../../../Helpers/Functions";
import AppContext from "../../../Context/AppContext";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";

const BillingSetting = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { isLoading, setIsLoading } = useContext(AppContext);
  setIsLoading(false);

  useEffect(() => {
    allPaymentMethods().then((response) => {
      setPaymentMethods(response?.data);
    });
  }, []);
  const handleTextClick = (step) => {
    setActiveStep(step); // Set active step based on clicked text
  };
  const handleDeletePaymentMethod = async (id) => {
    setIsLoading(true);
    await DeletePaymentMethod(id);
    setPaymentMethods((prevMethods) =>
      prevMethods.filter((method) => method.id !== id)
    );
    setIsLoading(false);
  };

  return (
    <Container>
      <Container className={STYLES?.BILLING_SETTING_CONTAINER}>
        <>
          <Container className={STYLES?.BILLING_SETTING_HEADER}>
            <Container
              className={`${
                activeStep === 1
                  ? STYLES?.BILLING_SETTING_NAME_ACTIVE
                  : STYLES?.BILLING_SETTING_NAME
              }`}
              onClick={() => handleTextClick(1)}
            >
              <Text variant="p" onClick={() => handleTextClick(1)}>
                {CONSTANTS?.NEW_PAYMENT_METHOD}
              </Text>
            </Container>
            <Container
              className={`${
                activeStep === 2
                  ? STYLES?.BILLING_SETTING_NAME_ACTIVE
                  : STYLES?.BILLING_SETTING_NAME
              }`}
              onClick={() => handleTextClick(2)}
            >
              <Text variant="p" onClick={() => handleTextClick(2)}>
                {CONSTANTS?.SAVED_PAYMENT}
              </Text>
            </Container>
          </Container>
        </>
      </Container>
      {isLoading ? (
        <CustomLoader />
      ) : (
        activeStep === 2 && (
          <SavedPayment
            paymentMethods={paymentMethods}
            clickable={false}
            onDeletePayment={handleDeletePaymentMethod}
            deleteText={CONSTANTS?.DELETE}
          />
        )
      )}

      {activeStep === 1 && <PaymentSetting />}
    </Container>
  );
};

export default BillingSetting;
