import React, { useContext } from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import { useNavigate } from "react-router-dom";
import "./UserProfile.scss";
import { Text } from "../../Atoms/Typography/Text";
import Button from "../../Atoms/Button";
import AppContext from "../../../Context/AppContext";
import Avatar from "../../Atoms/Avatar/Avatar";

const UserProfile = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserType, userProfile } =
    useContext(AppContext);
  const adminName = localStorage.getItem("adminName");

  const handleLogout = () => {
    const userType = localStorage.getItem("userType");
    localStorage.clear();
    setIsAuthenticated(false);
    setUserType(null);
    if (userType === "user") {
      navigate(ROUTES?.USER_LOGIN);
    } else {
      navigate(ROUTES?.ADMIN_LOGIN);
    }
  };

  return (
    <Container className={STYLES?.USER_PROFILE}>
      {userProfile && (
        <Avatar
          imageUrl={userProfile?.image_url}
          name={userProfile?.name ? userProfile?.name : adminName}
        />
      )}
      <Container className={STYLES?.PROFILE_DETAIL}>
        <Text variant="p" className={STYLES?.PROFILE_NAME}>
          {userProfile?.name || adminName}
        </Text>
        <Button
          className={STYLES?.LOGOUT}
          buttonText={CONSTANTS?.SIGNOUT}
          onClick={handleLogout}
        />
      </Container>
    </Container>
  );
};

export default UserProfile;
