import { CONSTANTS } from "../../../Helpers/Constants";

const DownloadCell = ({ row }) => {
  const documentUrl =
    row?.revisions?.[row?.revisions?.length - 1]?.document_url || "";

  const handleDownloadClick = async () => {
    if (documentUrl) {
      try {
        const link = document.createElement("a");
        link.href = documentUrl;
        link.download = "document.pdf";
        link.click();
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    }
  };

  return (
    <div>
      {documentUrl ? (
        <span
          style={{
            cursor: "pointer",
            color: "#219653",
            textDecoration: "underline",
            fontSize: "14px",
            fontFamily: "Inter",
            fontWeight: "500",
          }}
          onClick={handleDownloadClick}
        >
          {CONSTANTS?.DOWNLOAD}
        </span>
      ) : (
        <span
          style={{
            color: "#394150",
            fontSize: "14px",
            fontFamily: "Inter",
            fontWeight: "500",
          }}
        >
          N/A
        </span>
      )}
    </div>
  );
};
export default DownloadCell;
