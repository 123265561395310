import React from "react";
import Container from "../Container/Container";
import { Text } from "../Typography/Text";
import { STYLES } from "../../../Helpers/Constants";
import "./UserTitleText.scss"

const UserTitleText = ({ label }) => {
  return (
    <Container className={STYLES?.USER_TITLE_TEXT}>
      <Text>{label}</Text>
    </Container>
  );
};

export default UserTitleText;
