import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "./Constants";
import AppContext from "../Context/AppContext";

const PrivateRoute = ({ element }) => {
  const { isAuthenticated, userType } = useContext(AppContext);

  return isAuthenticated && userType === "admin" ? (
    <Navigate to={ROUTES?.USER_MANAGEMENT} />
  ) : isAuthenticated && userType === "user" ? (
    <Navigate to={ROUTES?.ORDER} />
  ) : (
    element
  );
};

export default PrivateRoute;
