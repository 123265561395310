import React from "react";
import "./ContentBar.scss";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import ProductSample from "../ProductSample/ProductSample";
import blogIcon from "../../../Assets/LandingPageAssets/blog_icon.svg";
import webIcon from "../../../Assets/LandingPageAssets/web_icon.svg";
import artIcon from "../../../Assets/LandingPageAssets/art_icon.svg";
import fulwebIcon from "../../../Assets/LandingPageAssets/fullweb_icon.svg";
const ContentBar = () => {
  return (
    <Container className={STYLES?.CONTENT_BAR}>
      <ProductSample
        icon={blogIcon}
        productTitle={CONSTANTS?.BLOG_POST_TITLE}
        productDescription={CONSTANTS?.BLOG_DETAILS}
      />
      <ProductSample
        icon={webIcon}
        productTitle={CONSTANTS?.WEB_CONTENT}
        productDescription={CONSTANTS?.WEB_CONTENT_DETAILS}
      />
      <ProductSample
        icon={artIcon}
        productTitle={CONSTANTS?.ARTICLES}
        productDescription={CONSTANTS?.ARTICLES_DETAILS}
      />
      <ProductSample
        icon={fulwebIcon}
        productTitle={CONSTANTS?.FULL_WEB}
        productDescription={CONSTANTS?.FULL_WEB_DETAILS}
      />
    </Container>
  );
};

export default ContentBar;
