import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import DealsTableTitleRow from "../../Atoms/DealsTableTitleRow";
import ClientSerach from "../../Molecules/ClientSearch/ClientSearch";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import { columns, customStyles } from "./OrderColumn";
import CreateNewUser from "../../Molecules/CreateNewUser/CreateNewUser";
import "./Order.scss";
import CustomPagination from "../../Molecules/CustomPagination/CustomPagination";
import Container from "../../Atoms/Container/Container";
import { OrderData, formatFilter } from "../../../Helpers/Functions";
import OrderManagementCards from "../../Atoms/OrderManagementCards";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import { Orderoptions } from "../../../Helpers/FilterOptions";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
import BoldBlackHeading from "../../Atoms/BoldBlackHeading/BoldBlackHeading";
import RowSpace from "../../Atoms/RowSpace/RowSpace";

const headingArr = [
  CONSTANTS?.NAME,
  CONSTANTS?.ORDER_TYPE,
  CONSTANTS?.DATE,
  CONSTANTS?.STATUS,
  CONSTANTS?.DOWNLOAD,
  CONSTANTS?.ACTION,
];

let limit = 6;

const Order = () => {
  const [orderData, setOrderData] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [stat, setstats] = useState({
    total: 0,
    inProgress: 0,
    completed: 0,
  });
  const [ShowDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const formattedFilter = formatFilter(filter);
    OrderData({
      limit,
      currentPage,
      search,
      filter: formattedFilter,
      setOrderData,
      setLoading,
      setCount,
      setstats,
    });
    // let interval = setInterval(
    //   () =>
    //     OrderData({
    //       limit,
    //       currentPage,
    //       search,
    //       filter: formattedFilter,
    //       setOrderData,
    //       setLoading,
    //       setCount,
    //       setstats,
    //     }),
    //   100000
    // );
    // //destroy interval on unmount
    // return () => clearInterval(interval);
  }, [search, filter, currentPage]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };
  const CreateOrder = () => {
    navigate(`${ROUTES?.ORDER}${ROUTES?.CREATE_NEW_ORDER_FORM}`);
  };

  return (
    <Container className={STYLES?.USER_ORDER_PAGE}>
      <OrderManagementCards
        className={STYLES?.USER_ORDER_CARD_CONTAINER}
        stat={stat}
      />
      <Container className={STYLES?.MainContent}>
        <BoldBlackHeading text={CONSTANTS?.ORDER} />
        <Container className={STYLES?.SEARCH_CONTENT}>
          <ClientSerach
            onSearch={handleSearch}
            setSearch={setSearch}
            filter={filter}
            setFilter={setFilter}
            ShowDropdown={ShowDropdown}
            setShowDropdown={setShowDropdown}
            filterOptions={{ Status: Orderoptions }}
          />
          <Container>
            {count !== 0 && (
              <PrimaryButton
                buttonText={CONSTANTS?.CREATE_NEW_ORDER}
                onClick={CreateOrder}
              />
            )}
          </Container>
        </Container>
        <RowSpace margin={20} />
        {!loading ? (
          <>
            {orderData && count === 0 ? (
              <>
                <DealsTableTitleRow list={headingArr} />
                {!loading && (
                  <CreateNewUser
                    buttonText={CONSTANTS?.CREATE_NEW_ORDER}
                    text={CONSTANTS?.NO_ORDER_ADDED_YET}
                    text1={"No order"}
                    onClicked={CreateOrder}
                  />
                )}
              </>
            ) : (
              <div className={STYLES?.CUSTOM_TABLE}>
                <DataTable
                  customStyles={customStyles}
                  noHeader={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={"45dvh"}
                  columns={columns(navigate)}
                  data={orderData}
                  pagination
                  paginationPerPage={limit}
                  paginationRowsPerPageOptions={[10, 20, 30]}
                  paginationComponent={(props) => (
                    <CustomPagination
                      {...props}
                      currentPage={currentPage}
                      limit={limit}
                      count={count}
                      setCurrentPage={setCurrentPage}
                      onChangePage={handleChangePage}
                    />
                  )}
                />
              </div>
            )}
          </>
        ) : (
          <CustomLoader />
        )}
      </Container>
    </Container>
  );
};

export default Order;
