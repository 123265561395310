import React from "react";
import Container from "../../Atoms/Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import "./UserLogin.scss";
import UserLoginBackground from "../../Atoms/UserLoginBackground";
import SignIn from "../SignIn";
const UserLogin = () => {
  return (
    <Container className={STYLES?.USER_LOGIN_PAGE}>
      <SignIn />
      <UserLoginBackground />
    </Container>
  );
};

export default UserLogin;
