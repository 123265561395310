import React from "react";
import Container from "../Container/Container";
import "./Monogram.scss";
import { STYLES } from "../../../Helpers/Constants";
const Monogram = ({ imageSrc }) => {
  return (
    <Container>
      <img
        src={imageSrc}
        alt="Header Logo"
        className={STYLES?.MONOGRAM_CONTAINER}
      />
    </Container>
  );
};

export default Monogram;
