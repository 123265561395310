import React from "react";
import Container from "../../Atoms/Container/Container";
import "./Header.scss";
import { STYLES } from "../../../Helpers/Constants";
import HeaderLogo from "../../Atoms/HeaderLogo/HeaderLogo";
import logo from "../../../Assets/LandingPageAssets/logo-word-well.png";
import HeaderButton from "../HeaderButton/HeaderButton";

const Header = () => {
 
  return (
    <Container className={STYLES?.HEADER_CONTAINER}>
      <HeaderLogo imageSrc={logo} />
      <HeaderButton  />
    </Container>
  );
};

export default Header;
