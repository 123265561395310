import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import { Text } from "../../Atoms/Typography/Text";
import "./SavedPayment.scss";
import Image from "../../Atoms/Image";
import visa_card_icon from "../../../Assets/Visa_Card.png";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
const SavedPayment = ({
  paymentMethods,
  clickable,
  onCardClick,
  onDeletePayment,
  deleteText,
}) => {
  const handleContainerClick = (cardData) => {
    if (clickable && onCardClick) {
      onCardClick(cardData);
    }
  };
  const handleTextClick = (cardData) => {
    onDeletePayment(cardData?.id);
  };
  return (
    <Container>
      {paymentMethods?.length > 0 &&
        paymentMethods?.map((item, index) => (
          <Container
            key={index}
            className={`${STYLES?.SAVED_PAYMENT_INNER_CONTAINER} ${
              !clickable && deleteText === ""
                ? STYLES?.FADED_CARDS_CONTAINER
                : clickable
                ? STYLES?.CLICKABLE_CONTAINER
                : ""
            }`}
            onClick={() => handleContainerClick(item)}
          >
            {!clickable && deleteText === "" ? (
              <CustomLoader />
            ) : (
              <Container className={STYLES?.SAVED_PAYMENT_CONTENT}>
                <Container className={STYLES?.PAYMENT_INFO}>
                  <Text className={STYLES?.PAYMENT_INFO_TEXT}>
                    {CONSTANTS?.PAYMENT_INFORMATION}
                  </Text>
                  <Container className={STYLES?.CARD_INFO}>
                    {item?.card?.brand && (
                      <Image
                        src={visa_card_icon}
                        alt={item.card.brand}
                        className={STYLES?.CARD_ICON}
                      />
                    )}
                    <Text className={STYLES?.REGULAR_BLACK_TEXT}>
                      {item?.card?.brand
                        ? item.card.brand.charAt(0).toUpperCase() +
                          item.card.brand.slice(1)
                        : ""}
                      {CONSTANTS?.ENDING_WITH}
                      {item?.card?.last4}
                    </Text>
                  </Container>
                </Container>
                <Container
                  className={STYLES?.UPLOAD_TEXT}
                  onClick={() => handleTextClick(item)}
                >
                  {deleteText}
                </Container>
              </Container>
            )}
          </Container>
        ))}
    </Container>
  );
};

export default SavedPayment;
