import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import "./SignIn.scss";
import Container from "../../Atoms/Container/Container";
import { useForm } from "react-hook-form";
import { Text } from "../../Atoms/Typography/Text";
import { yupResolver } from "@hookform/resolvers/yup";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import Image from "../../Atoms/Image";
import logo from "../../../Assets/logo.svg";
import HyperLink from "../../Atoms/HyperLink/HyperLink";
import InputField from "../../Atoms/InputField/InputField";
import {
  clientSignIn,
  fetchUserProfile,
  signInUser,
} from "../../../Helpers/Functions";
import InputFieldPassword from "../../Atoms/InputFieldPassword";
import { defaultValues, schema } from "./FormSchema";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import AppContext from "../../../Context/AppContext";
import { errorToast, successToast } from "../../../Helpers/useToast";

const SignIn = () => {
  const { setIsAuthenticated, setUserType, setUserProfile } =
    useContext(AppContext);
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let isError = false;
  const formClass =
    isError || ErrorMessage ? STYLES?.LOGIN_FORM_ERROR : STYLES?.LOGIN_FORM;

  const InputFieldClass = isError
    ? STYLES?.INPUT_FIELD_ERROR
    : STYLES?.LOGIN_INPUT_FIELD;

  const FormHeaderClass =
    location?.pathname === ROUTES?.USER_LOGIN
      ? STYLES?.USER_LOGIN_FORM_HEADER
      : STYLES?.LOGIN_FORM_HEADER;

  const LoginPageClass =
    location?.pathname === ROUTES?.USER_LOGIN
      ? STYLES?.LOGIN_PAGE_CLIENT
      : STYLES?.LOGIN_PAGE;

  const UserLogin = location?.pathname === ROUTES?.USER_LOGIN && true;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const storeAccessToken = (idToken, refreshToken, returnSecureToken) => {
    localStorage.setItem("accessToken", idToken);
    localStorage.setItem("refresh_token", refreshToken);
    localStorage.setItem("remember_me", returnSecureToken);
  };

  const handleCheckboxChange = (e) => {
    setisChecked(e.target.checked);
  };

  const onSubmit = async (data) => {
    const { Email: email, Password: password } = data;
    if (!email || !password) {
      setErrorMessage("enter correct credentials");
      return;
    }
    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }
    const returnSecureToken = isChecked;
    if (UserLogin) {
      try {
        const responseData = await clientSignIn(email, password, true);

        localStorage.setItem("userType", "user");
        sessionStorage.setItem("userType", "user");
        storeAccessToken(
          responseData.idToken,
          responseData.refreshToken,
          returnSecureToken
        );

        fetchUserProfile()
          .then((res) => {
            setUserProfile(res);

            if (res?.first_login && res?.status !== "signedup") {
              navigate(ROUTES?.CREATE_NEW_PASSWORD);
              localStorage.setItem("user_email", res?.email);
              localStorage.setItem("firstLogin", res?.first_login);
            } else {
              setIsAuthenticated(true);
              setUserType("user");
              navigate(ROUTES?.ORDER);
            }
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      } catch (error) {
        if (error.message.includes("Authentication failed")) {
          setErrorMessage(CONSTANTS?.ERROR_MESSAGE_API_FAIL);
          setIsAuthenticated(false);
          setUserType(null);
        }
      }
    } else {
      try {
        const responseData = await signInUser(email, password, true);
        if (responseData?.email === "admin@wordwell.com") {
          localStorage.setItem("userType", "admin");
          sessionStorage.setItem("userType", "admin");

          localStorage.setItem(
            "adminName",
            responseData?.displayName || "Admin Wordwell"
          );
        }
        storeAccessToken(
          responseData.idToken,
          responseData.refreshToken,
          returnSecureToken
        );
        setIsAuthenticated(true);
        setUserType("admin");
        navigate(ROUTES?.USER_MANAGEMENT);
      } catch (error) {
        localStorage.removeItem("userType");
        setIsAuthenticated(false);
        setUserType(null);
        if (error.message.includes("Authentication failed")) {
          setErrorMessage(CONSTANTS?.ERROR_MESSAGE_API_FAIL);
        }
      }
    }
  };

  useEffect(() => {
    const loggedOut = localStorage.getItem("LogOut");

    if (loggedOut) {
      errorToast(loggedOut);
      setTimeout(() => {
        localStorage.removeItem("LogOut");
      }, 1000);
    }
    if (localStorage.getItem("NewPassword")) {
      successToast(CONSTANTS?.PASSWORD_CHANGED_SUCCESS);
      localStorage.removeItem("NewPassword");
    }
  }, []);
  return (
    <Container className={STYLES?.USER_LOGIN_FORM_PARENT}>
      <Container className={LoginPageClass}>
        <Container className={STYLES?.LOGIN_FORM_CONTAINER}>
          <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
            <Container className={FormHeaderClass}>
              <Container>
                <Image src={logo} alt="Logo" />
              </Container>
              <Container className={STYLES?.LOGIN_TEXT}>
                <Text>{CONSTANTS?.LOGIN_TEXT} </Text>
              </Container>
            </Container>
            <InputField
              name={CONSTANTS?.EMAIL}
              field={CONSTANTS?.EMAIL}
              type={CONSTANTS?.EMAIL_TYPE}
              placeholder={CONSTANTS?.EMAIL_PLACEHOLDE5}
              register={register}
              errors={errors}
              maxLength={50}
              label={CONSTANTS?.EMAIL_ADDRESS}
              onChange={(e) => {
                setValue(CONSTANTS?.EMAIL, e.target.value);
              }}
              className={InputFieldClass}
            />
            <InputFieldPassword
              name={CONSTANTS?.PASSWORD}
              type={CONSTANTS?.PASSWORD_TYPE}
              field={CONSTANTS?.PASSWORD}
              placeholder=""
              register={register}
              label={CONSTANTS?.PASSWORD}
              disabled={false}
              errors={errors}
              maxLength={50}
              onChange={(e) => {
                setValue(CONSTANTS?.PASSWORD, e.target.value);
              }}
              className={InputFieldClass}
            />
            <Container className={STYLES?.FORM_BUTTON}>
              <Container className={STYLES?.REMEMBER_ME}>
                <input
                  type={CONSTANTS?.CHECKBOX}
                  className={STYLES?.LOGIN_CHECKBOX}
                  name={CONSTANTS?.REMEMBERME}
                  onChange={handleCheckboxChange}
                />
                <Text>{CONSTANTS?.REMEMBER_ME} </Text>
              </Container>
              <HyperLink
                href={ROUTES?.USER_FORGOT_PASSWORD}
                text={CONSTANTS?.FORGET_PASSWORD}
                className={STYLES?.FORGOT_BUTTON}
              />
            </Container>
            <PrimaryButton
              type={CONSTANTS?.BUTTON_TYPE}
              buttonText={CONSTANTS?.SIGN_IN}
            />
            {UserLogin && (
              <Container className={STYLES?.SIGN_UP_BOTTOM}>
                <Text className={STYLES?.LIGHT_GREY_TEXT}>
                  {CONSTANTS?.SIGN_UP_PROMPT_TEXT}
                  <Link
                    to={ROUTES?.USER_SIGN_UP}
                    className={STYLES?.UPLOAD_TEXT}
                    target="_blank"
                  >
                    {CONSTANTS?.SIGN_UP}
                  </Link>
                </Text>
              </Container>
            )}

            {ErrorMessage && (
              <p className={STYLES?.Login_Error_Message}>{ErrorMessage}</p>
            )}
          </form>
        </Container>
        <Container className={STYLES?.All_Rights_Reserved}>
          <Text>{CONSTANTS?.ALL_RIGHTS_TEXT}</Text>
        </Container>
      </Container>
    </Container>
  );
};
export default SignIn;
