import * as yup from "yup";

export const schema = yup.object().shape({

  Password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(50, "Password must be at most 50 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("Password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const defaultValues = {
  Password: "",
  confirmPassword: "",
};
