import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import InputField from "../../Atoms/InputField/InputField";
import Image from "../../Atoms/Image/Image";
import SearchIcon from "../../../Assets/Search.png";
import Container from "../../Atoms/Container/Container";
import Filter from "../../Atoms/Filter/Filter";

const ClientSerach = ({
  filter,
  onSearch,
  setSearch,
  setFilter,
  ShowDropdown,
  setShowDropdown,
  filterOptions,
}) => {
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearch(newValue);
    onSearch(newValue);
  };
  return (
    <Container className={STYLES?.SEARCH_CONTAINER}>
      <Container className={STYLES?.SEARCH}>
        <Container className={STYLES?.SEARCH_FIELD}>
          <InputField
            className={STYLES?.INPUTFILED}
            placeholder={CONSTANTS?.SEARCH}
            onChange={handleInputChange}
          />
          <Image src={SearchIcon} className={STYLES?.SEARCH_ICON} alt="logo" />
        </Container>
        <Filter
          selectedOption={filter}
          setSelectedOption={setFilter}
          visibility={ShowDropdown}
          setVisibility={setShowDropdown}
          options={filterOptions}
        />
      </Container>
    </Container>
  );
};

export default ClientSerach;
