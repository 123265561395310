import { useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Text } from "../Typography/Text";
import Image from "../Image/Image";
import "./BreadCrumbs.scss";
import HomeIcon from "../../../Assets/Home.png";
import ArrowIcon from "../../../Assets/Arrow.png";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import Container from "../Container/Container";
import Userprofile from "../../Molecules/UserProfile/UserProfile";
import UserTitleText from "../../Atoms/UserTitleText";
import AppContext from "../../../Context/AppContext";
import { fetchUserProfile } from "../../../Helpers/Functions";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";

const Breadcrumbs = ({ breadcrumbs, selectedLabel, client, toggleMobileMenu }) => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const labelSegments = selectedLabel?.split("/")?.filter(Boolean);
  const pathLength = pathSegments.length;
  const { setUserProfile } = useContext(AppContext);
  const filteredBreadcrumbs = breadcrumbs?.filter(
    (breadcrumb) =>
      breadcrumb?.link === selectedLabel ||
      breadcrumb?.link?.endsWith(labelSegments && labelSegments[2])
  );
  useEffect(() => {
    fetchUserProfile()
      .then((result) => {
        setUserProfile(result);
      })
      .catch((err) => {
        console.error("err: ", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={STYLES?.BREADCRUMBS}>
      <HamburgerMenu onToggleMenu={toggleMobileMenu} />

      {client && pathLength === 2 ? (
        <UserTitleText
          label={
            filteredBreadcrumbs[0]?.label === CONSTANTS?.MY_ORDER
              ? CONSTANTS?.DASHBOARD
              : filteredBreadcrumbs[0]?.label
          }
        />
      ) : (
        <Container className={STYLES?.BRREADCRUMBS_LINK}>
          {filteredBreadcrumbs?.map((breadcrumb, index) => {
            return (
              <span key={index} className={STYLES?.BRREADCRUMBS_LINK}>
                {index === 0 && <Image src={HomeIcon} />}
                <Image src={ArrowIcon} alt="Breadcrumb ArrowIcon" />
                {(breadcrumb?.link === "/user/order/create-new-order-form" ||
                  breadcrumb?.link.length > 3) && (
                  <>
                    <Link to="/user/order" className={STYLES?.Link_Text}>
                      <Text variant="p">{CONSTANTS?.MY_ORDER}</Text>
                    </Link>
                    <Image src={ArrowIcon} alt="Breadcrumb ArrowIcon" />
                    <Link to="" className={STYLES?.Link_Text}>
                      <Text variant="p">{breadcrumb?.label}</Text>
                    </Link>
                  </>
                )}
              </span>
            );
          })}
        </Container>
      )}
      <Userprofile />
    </Container>
  );
};

export default Breadcrumbs;
