import { ROUTES } from "../../../Helpers/Constants";

export const ViewButton = ({ row, navigate }) => {
  const onView = () => {
    const currentPath = window.location.pathname;
    const newPath = `${currentPath}${ROUTES?.CREATE_NEW_ORDER}/${row.id}`;
    navigate(newPath, {
      state: row,
    });
  };

  return <div onClick={onView}>View</div>;
};
