import React from "react";
import Container from "../Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import "./OrderManagementCards.scss";
import Card from "../Card";
import CheckIcon from "../../../Assets/check.svg";
import InProgress from "../../../Assets/progress.svg";

const OrderManagementCards = ({ stat }) => {
  return (
    <>
      <Container className={STYLES?.USER_ORDER_CARD_CONTAINER}>
        <Card
          className={STYLES?.CARD_TOTAL}
          CardTitle={CONSTANTS?.TOTAL_ORDERS}
          CardCount={stat?.total}
        />
        <Card
          className={STYLES?.CARD_IN_PROGRESS}
          CardTitle={CONSTANTS?.IN_PROGRESS}
          image={InProgress}
          CardCount={stat?.inProgress}
        />
        <Card
          className={STYLES?.CARD_COMPLETE}
          CardTitle={CONSTANTS?.COMPLETED}
          image={CheckIcon}
          CardCount={stat?.completed}
        />
      </Container>
    </>
  );
};

export default OrderManagementCards;
