import React from "react";
import "./UserBillingCards.scss";
import ArrowUp from "../../../Assets/ArrowUp.png";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import Card from "../../Atoms/Card";
import ButtonCard from "../../Atoms/ButtonCard/ButtonCard";
import { formatDate } from "../../../Helpers/DateFormat";

const UserBillingCards = ({ latestData, allData, allPaid }) => {
  const data = allPaid ? allData : latestData;
  return (
    <>
      <Container className={STYLES?.USER_ORDER_CARD_CONTAINER}>
        <Card
          className={STYLES?.CARD_DATE}
          CardTitle={CONSTANTS?.NEXT_INVOICE}
          CardCount={
            !allPaid ? formatDate(data?.qb_due_date) : "-- -- --"
          }
        />
        <Card
          className={STYLES?.CARD_INVOICE_TOTAL}
          CardTitle={CONSTANTS?.INVOICE_TOTAL}
          CardCount={"$" + (!allPaid && data?.amount ? data?.amount : "0")}
        />
        {!allPaid ? (
          <ButtonCard
            image={ArrowUp}
            className={STYLES?.USER_ORDER_CARD}
            CardText={"Pay Now"}
            link={data?.qb_invoice}
          />
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

export default UserBillingCards;
