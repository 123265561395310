import React from "react";
import { Text } from "../Typography/Text";
import { STYLES } from "../../../Helpers/Constants";
import RowSpace from "../RowSpace/RowSpace";

const Checkbox = ({ options, selectedOptions, setSelectedOptions }) => {
  const handleCheckboxChange = (value, category) => {
    const updatedOptions = { ...selectedOptions };

    if (!updatedOptions[category]) {
      updatedOptions[category] = [];
    }

    const selectedIndex = updatedOptions[category].indexOf(value);
    if (selectedIndex === -1) {
      updatedOptions[category].push(value);
    } else {
      updatedOptions[category].splice(selectedIndex, 1);
    }
    setSelectedOptions(updatedOptions);
  };

  return (
    <div>
      {Object.keys(options).map((category) => (
        <div className="checkbox-category" key={category}>
          <Text className={STYLES?.CATEGORY_TEXT}>{category}</Text>
          <RowSpace margin={8} />

          {options[category].map((option, index) => (
            <div className="checkbox-option" key={index}>
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                checked={selectedOptions[category]?.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value, category)}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Checkbox;
