import React from "react";
import { STYLES } from "../../../Helpers/Constants";
import "./DealsTableTitleRow.scss";
import { Text } from "../Typography/Text";
const HotDealsDetails = ({
  list
}) => {
  return (
    <div>
      <ul className={STYLES?.Deals_Details}>
        {list?.map((item) => {
          return (
            <li>
              <Text variant="p" className={STYLES?.Deal_Details_text}>
                {item}
              </Text>
            </li>
          )
        })}
      </ul>
    </div>
  );
};

export default HotDealsDetails;
