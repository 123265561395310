import React from "react";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import Container from "../Container/Container";
import "./UserLoginBackground.scss";
import { Text } from "../Typography/Text";

import Button from "../Button";
const UserLoginBackground = () => {
  return (
    <Container className={STYLES?.USER_LOGIN_FORM_BACKGROUND}>
      <Container className={STYLES?.USER_LOGIN_PAGE_OVERLAY}>
        <Text variant="h1" className="user-background-text">
          {CONSTANTS?.MARKTING_ENTHUSIASTS}
        </Text>
        <Button
          buttonText={CONSTANTS?.LEARN_MORE}
          className={STYLES?.LEARN_MORE}
        />
      </Container>
    </Container>
  );
};

export default UserLoginBackground;
